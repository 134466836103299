import { Condition, Process, StationType } from "../../../generated/data-contracts";
import { Button } from "../../../components/common/Button";
import { capitalize } from "../../../utils/text";
import { isSet } from "../../../utils/validation";
import { isMobile } from "react-device-detect";
import GeneratedInputText from "./GeneratedInputField";
import GeneratedInputTextArea from "./GeneratedInputTextArea";

export default function InputFields(props: {
    process: Process,
    processDraft: Process,
    onUpdateDraft: (process: Process) => void
    generatedProcess: Process | undefined,
    onUpdateGenerated: (process: Process) => void
}) {
    const { process, processDraft, onUpdateDraft, generatedProcess, onUpdateGenerated } = props;

    return <div className="flex flex-col gap-2">
        <GeneratedInputText
            labelTextColor="text-thriftlyGreyLight"
            textColor="text-thriftlyOffWhite"
            backgroundColor="bg-thriftlyBlackBackground"
            className={`border-2 ${!isSet(processDraft.title) ? "border-red-500" : "border-thriftlyGrey"}`}
            label="Title"
            placeholder="Title"
            originalValue={process.title}
            value={processDraft.title}
            generatedValue={generatedProcess?.title}
            onChange={(value) => onUpdateDraft({ ...processDraft, title: value })}
            onUndo={() => { onUpdateDraft({ ...processDraft, title: process.title }); onUpdateGenerated({ ...generatedProcess, title: undefined }) }}
        />
        <div className="flex flex-row gap-2 justify-between">
            {Object.values(Condition).map((condition) => {
                if (processDraft.condition === condition) {
                    return (
                        <Button
                            key={condition}
                            textColor="text-thriftlyOffWhite"
                            backgroundColor="bg-thriftlyGreyDark"
                            className={`w-full ${isMobile ? "h-12" : "h-16"} border-thriftlyGrey`}
                            onClick={() => onUpdateDraft({ ...processDraft, condition: condition })}
                        >
                            {capitalize(condition)}
                        </Button>
                    )
                } else {
                    return (
                        <Button
                            key={condition}
                            textColor="text-thriftlyGreyLight"
                            backgroundColor="bg-thriftlyBlackBackground"
                            className={`w-full ${isMobile ? "h-12" : "h-16"} border-thriftlyGreyDark`}
                            onClick={() => onUpdateDraft({ ...processDraft, condition: condition })}
                        >
                            {capitalize(condition)}
                        </Button>
                    )
                }
            })}
        </div>
        {process.stationType === StationType.StationTypeSoftline ? (
            <div className="grid grid-cols-2 gap-2">
                <GeneratedInputText
                    labelTextColor="text-thriftlyGreyLight"
                    textColor="text-thriftlyOffWhite"
                    backgroundColor="bg-thriftlyBlackBackground"
                    className={`border-2 ${!isSet(processDraft?.price) || processDraft?.price === "$" ? "border-red-500" : "border-thriftlyGrey"}`}
                    label="Price"
                    placeholder="Price"
                    prefix="$"
                    type="number"
                    originalValue={process.price?.replace("$", "") || ""}
                    value={processDraft.price?.replace("$", "") || ""}
                    generatedValue={generatedProcess?.price?.replace("$", "") || ""}
                    onChange={(value) => onUpdateDraft({ ...processDraft, price: `$${value}` })}
                    onUndo={() => { onUpdateDraft({ ...processDraft, price: process.price }); onUpdateGenerated({ ...generatedProcess, price: undefined }) }}
                />
                <GeneratedInputText
                    labelTextColor="text-thriftlyGreyLight"
                    textColor="text-thriftlyOffWhite"
                    backgroundColor="bg-thriftlyBlackBackground"
                    className="border-2 border-thriftlyGrey"
                    label="Brand"
                    placeholder="Brand"
                    type="text"
                    originalValue={process.brand}
                    value={processDraft.brand || ""}
                    generatedValue={generatedProcess?.brand || ""}
                    onChange={(value) => onUpdateDraft({ ...processDraft, brand: value })}
                    onUndo={() => { onUpdateDraft({ ...processDraft, brand: process.brand }); onUpdateGenerated({ ...generatedProcess, brand: undefined }) }}
                />
                <GeneratedInputText
                    labelTextColor="text-thriftlyGreyLight"
                    textColor="text-thriftlyOffWhite"
                    backgroundColor="bg-thriftlyBlackBackground"
                    className="border-2 border-thriftlyGrey"
                    label="Size"
                    placeholder="Size"
                    type="text"
                    originalValue={process.size}
                    value={processDraft.size || ""}
                    generatedValue={generatedProcess?.size}
                    onChange={(value) => onUpdateDraft({ ...processDraft, size: value })}
                    onUndo={() => { onUpdateDraft({ ...processDraft, size: process.size }); onUpdateGenerated({ ...generatedProcess, size: undefined }) }}
                />
                <GeneratedInputText
                    labelTextColor="text-thriftlyGreyLight"
                    textColor="text-thriftlyOffWhite"
                    backgroundColor="bg-thriftlyBlackBackground"
                    className="border-2 border-thriftlyGrey"
                    label="Category"
                    placeholder="Category"
                    type="text"
                    originalValue={process.category}
                    value={processDraft.category || ""}
                    generatedValue={generatedProcess?.category}
                    onChange={(value) => onUpdateDraft({ ...processDraft, category: value })}
                    onUndo={() => { onUpdateDraft({ ...processDraft, category: process.category }); onUpdateGenerated({ ...generatedProcess, category: undefined }) }}
                />
                <GeneratedInputText
                    labelTextColor="text-thriftlyGreyLight"
                    textColor="text-thriftlyOffWhite"
                    backgroundColor="bg-thriftlyBlackBackground"
                    className="border-2 border-thriftlyGrey"
                    label="Color"
                    placeholder="Color"
                    type="text"
                    originalValue={process.color}
                    value={processDraft.color || ""}
                    generatedValue={generatedProcess?.color}
                    onChange={(value) => onUpdateDraft({ ...processDraft, color: value })}
                    onUndo={() => { onUpdateDraft({ ...processDraft, color: process.color }); onUpdateGenerated({ ...generatedProcess, color: undefined }) }}
                />
                <GeneratedInputText
                    labelTextColor="text-thriftlyGreyLight"
                    textColor="text-thriftlyOffWhite"
                    backgroundColor="bg-thriftlyBlackBackground"
                    className="border-2 border-thriftlyGrey"
                    label="Materials"
                    placeholder="Materials"
                    type="text"
                    originalValue={process.material}
                    value={processDraft.material || ""}
                    generatedValue={generatedProcess?.material}
                    onChange={(value) => onUpdateDraft({ ...processDraft, material: value })}
                    onUndo={() => { onUpdateDraft({ ...processDraft, material: process.material }); onUpdateGenerated({ ...generatedProcess, material: undefined }) }}
                />
            </div>
        ) : (
            <GeneratedInputText
                labelTextColor="text-thriftlyGreyLight"
                textColor="text-thriftlyOffWhite"
                backgroundColor="bg-thriftlyBlackBackground"
                className={`border-2 ${!isSet(processDraft?.price) || processDraft?.price === "$" ? "border-red-500" : "border-thriftlyGrey"} w-full`}
                label="Price"
                placeholder="Price"
                prefix="$"
                type="number"
                originalValue={process.price?.replace("$", "") || ""}
                value={processDraft.price?.replace("$", "") || ""}
                generatedValue={generatedProcess?.price?.replace("$", "") || ""}
                onChange={(value) => onUpdateDraft({ ...processDraft, price: `$${value}` })}
                onUndo={() => { onUpdateDraft({ ...processDraft, price: process.price }); onUpdateGenerated({ ...generatedProcess, price: undefined }) }}
            />
        )}
        <GeneratedInputTextArea
            labelTextColor="text-thriftlyGreyLight"
            textColor="text-thriftlyOffWhite"
            backgroundColor="bg-thriftlyBlackBackground"
            className="border-2 border-thriftlyGrey w-full h-48"
            label="Description"
            placeholder="Description"
            originalValue={process.notes}
            value={processDraft.notes || ""}
            generatedValue={generatedProcess?.notes}
            onChange={(value) => onUpdateDraft({ ...processDraft, notes: value })}
            onUndo={() => { onUpdateDraft({ ...processDraft, notes: process.notes }); onUpdateGenerated({ ...generatedProcess, notes: undefined }) }}
        />
    </div>
}