import { useContext, useEffect, useState } from "react";
import { ROUTES, SESSION_KEY, TOKEN_KEY, USER_SESSIONS_KEY } from "../constants";
import { OrgContext } from "../components/context/OrgContext";
import { LocationStyles } from "../generated/data-contracts";
import { getOrgStyles } from "../utils/localStorage";

function LogoutPage() {
    const { setOrgContext } = useContext(OrgContext);
    const [orgStyles] = useState<LocationStyles>(getOrgStyles());

    useEffect(() => {
        localStorage.removeItem(TOKEN_KEY);
        localStorage.removeItem(SESSION_KEY);
        localStorage.removeItem(USER_SESSIONS_KEY);
        setOrgContext();
    }, []);

    return (
        <div className="h-dvh flex items-center justify-center">
            <div className="flex flex-col items-center gap-1 bg-thriftlyOffWhite p-10 border border-solid rounded-lg w-[400px]">
                <img width="200px" src={orgStyles.logo} alt="logo" />

                <p className="mt-4 text-thriftlyForest">You have been successfully logged out.</p>

                <hr className="h-px my-4 bg-thriftlyForest w-full" />

                <a href={ROUTES.LOGIN} className="text-thriftlyForest">Back to log in</a>
            </div>
        </div >
    );
}

export default LogoutPage;
