import { useState } from "react";
import { getOrgStyles } from "../../utils/localStorage";
import { LocationStyles, UserRole } from "../../generated/data-contracts";

export default function Itembadge({ role, itemsCount }: { role: UserRole, itemsCount?: number }) {
    const [orgStyles] = useState<LocationStyles>(getOrgStyles());

    const suffix = () => {
        if (itemsCount === 1) {
            return role === UserRole.RolePricer ? "item today" : "item left";
        }
        return role === UserRole.RolePricer ? "items today" : "items left";
    }

    return (
        <div className="flex items-center justify-end h-[20px] shrink-0">
            <div
                className="rounded text-xs uppercase py-1 px-2"
                style={{ backgroundColor: orgStyles.primaryColor, color: orgStyles.secondaryColor }}>
                {itemsCount !== undefined ? itemsCount : '-'} {suffix()}
            </div>
        </div>
    );
}
