import { forwardRef } from "react";
import cn from "../../utils/cn";

type Props = React.ButtonHTMLAttributes<HTMLButtonElement> & {
  className?: string;
  backgroundColor?: string;
  textColor?: string;
  disabled?: boolean;
};

export const StyledButton = forwardRef<HTMLButtonElement, Props>(
  ({ className, backgroundColor, textColor, disabled, ...props }, ref) => {

    const getTextColor = () => {
      if (disabled) return "#5B5F61";
      if (textColor) return textColor;
      if (backgroundColor) return "#E3E5E7";
      return "#0B1B14";
    };

    return (
      <button
        style={{ background: disabled ? "#EAECED" : backgroundColor, color: getTextColor() }}
        className={cn(
          "rounded-thriftlyButton h-12 px-4 font-semibold",
          "disabled:bg-thriftlyOffWhite disabled:text-thriftlyGreyLight disabled:cursor-auto",
          "inline-flex items-center justify-center cursor-pointer border border-solid gap-2 whitespace-nowrap",
          `body-b2-medium shadow-sm bg-thriftlyOffWhite`,
          "focus-visible:ring-1 focus-visible:ring-button-secondary-hover focus-visible:ring-opacity-20 focus-visible:bg-button-secondary outline-none",
          className,
          `${disabled ? "cursor-not-allowed" : ""}`
        )}
        ref={ref}
        {...props}
        type={props.type ?? "button"}
      >
        {props.children}
      </button>
    );
  }
);
StyledButton.displayName = "StyledButton";
