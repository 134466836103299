import { useRef } from "react";
import { Comparable } from "../../../generated/data-contracts";
import ComparableTileMobile from "./ComparableTileMobile";

export default function ComparableSelectorMobile(props: {
    comparables: Comparable[];
    onTakePicture: () => void;
    onSelectComparable: (comparable: Comparable) => void;
    onSelectImage: (image: string) => void;
}) {
    const { comparables, onTakePicture, onSelectComparable, onSelectImage } = props;
    const scrollRef = useRef<HTMLDivElement>(null);

    return (
        <div className="h-full flex flex-col overscroll-y-auto overflow-y-auto">
            <div className="flex flex-row gap-2 pl-4 py-2 text-white text-nowrap">
                Select a comp or <span className="underline cursor-pointer" onClick={() => onTakePicture()}>take a picture of the label</span>
            </div>
            <div className="flex shrink flex-col gap-4 px-4 pb-4" ref={scrollRef}>
                {comparables?.map((comparable: Comparable, idx: number) => {
                    return (
                        <div key={idx}>
                            <ComparableTileMobile
                                comparable={comparable}
                                onClick={(c: Comparable) => {
                                    onSelectComparable(c);
                                    if (scrollRef.current) {
                                        scrollRef.current.scroll({
                                            top: 0,
                                            behavior: "smooth"
                                        });
                                    }
                                }}
                                onClickImage={(c: Comparable) => { onSelectImage(c.image!); }}
                            />
                        </div>
                    );
                })}
            </div>
        </div>
    );
}
