import { ReactNode } from "react";
import { MdCheckCircle } from "react-icons/md";

export default function ScanTile(props: {
    state: "disabled" | "active" | "completed";
    title: string;
    subtitle?: string;
    image: string;
    children: ReactNode;
}) {
    const { state, title, subtitle, image, children } = props;

    return (
        <div
            className={`flex flex-col gap-4 text-white border-2 p-4 rounded-thriftlyButton w-full
                ${state === "active" ? "border-thriftlyGreyLight" : "border-thriftlyGreyDark"}`}
        >
            <div className="flex flex-row justify-between">
                <div className="flex flex-col w-2/3">
                    <div className="text-xl text-thriftlyOffWhite">{title}</div>
                    <div className="text-thriftlyGreyLight">{subtitle}</div>
                </div>
                <div className="flex items-center">
                    {state === "disabled" && <div className="rounded-full border-2 border-thriftlyGreyDark w-[32px] h-[32px]" />}
                    {state === "active" && <div className="rounded-full border-2 border-[#00B764] w-[32px] h-[32px] bg-thriftlyGreyDark" />}
                    {state === "completed" && <div className="bg-white rounded-full border-2 border-[#00B764] w-[36px] h-[36px] items-center justify-center">
                        <MdCheckCircle className="fill-[#00B764] w-[32px] h-[32px] scale-[130%]" />
                    </div>}
                </div>
            </div>
            {state === "active" && (
                <>
                    <div className="flex justify-center rounded-lg bg-thriftlyGreyDark h-[256px] justify-center">
                        <img src={image} alt="barcode scanner" className="object-fill" />
                    </div>
                    {children}
                </>
            )}
        </div>
    );
}
