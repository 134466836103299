/* eslint-disable */
/* tslint:disable */
/*
 * ---------------------------------------------------------------
 * ## THIS FILE WAS GENERATED VIA SWAGGER-TYPESCRIPT-API        ##
 * ##                                                           ##
 * ## AUTHOR: acacode                                           ##
 * ## SOURCE: https://github.com/acacode/swagger-typescript-api ##
 * ---------------------------------------------------------------
 */

import {
  CreateSessionRequest,
  CreateUserRequest,
  ErrorResponse,
  LoginRequest,
  LoginResponse,
  RecoverPasswordRequest,
  ResetPasswordRequest,
  UpdateUserRequest,
  UserResponse,
  UsersResponse,
} from "./data-contracts";
import { ContentType, HttpClient, RequestParams } from "./http-client";

export class Users<SecurityDataType = unknown> extends HttpClient<SecurityDataType> {
  /**
   * @description get all users
   *
   * @tags users
   * @name UsersList
   * @summary Get all users
   * @request GET:/users
   * @secure
   */
  usersList = (params: RequestParams = {}) =>
    this.request<UsersResponse, ErrorResponse>({
      path: `/users`,
      method: "GET",
      secure: true,
      type: ContentType.Json,
      format: "json",
      ...params,
    });
  /**
   * @description create a user
   *
   * @tags users
   * @name UsersCreate
   * @summary Create a user
   * @request POST:/users
   * @secure
   */
  usersCreate = (requestBody: CreateUserRequest, params: RequestParams = {}) =>
    this.request<UserResponse, ErrorResponse>({
      path: `/users`,
      method: "POST",
      body: requestBody,
      secure: true,
      type: ContentType.Json,
      format: "json",
      ...params,
    });
  /**
   * @description log in a user
   *
   * @tags users
   * @name LoginCreate
   * @summary Log in a user
   * @request POST:/users/login
   */
  loginCreate = (requestBody: LoginRequest, params: RequestParams = {}) =>
    this.request<LoginResponse, ErrorResponse>({
      path: `/users/login`,
      method: "POST",
      body: requestBody,
      type: ContentType.Json,
      format: "json",
      ...params,
    });
  /**
   * @description send a password recovery email to a user's email address
   *
   * @tags users
   * @name RecoverPasswordCreate
   * @summary Recover a user's password
   * @request POST:/users/recover-password
   */
  recoverPasswordCreate = (requestBody: RecoverPasswordRequest, params: RequestParams = {}) =>
    this.request<void, ErrorResponse>({
      path: `/users/recover-password`,
      method: "POST",
      body: requestBody,
      type: ContentType.Json,
      ...params,
    });
  /**
   * @description reset a user's password
   *
   * @tags users
   * @name ResetPasswordCreate
   * @summary Reset a user's password
   * @request POST:/users/reset-password
   */
  resetPasswordCreate = (requestBody: ResetPasswordRequest, params: RequestParams = {}) =>
    this.request<LoginResponse, ErrorResponse>({
      path: `/users/reset-password`,
      method: "POST",
      body: requestBody,
      type: ContentType.Json,
      format: "json",
      ...params,
    });
  /**
   * @description create a session
   *
   * @tags users
   * @name SessionCreate
   * @summary Create a session
   * @request POST:/users/session
   * @secure
   */
  sessionCreate = (requestBody: CreateSessionRequest, params: RequestParams = {}) =>
    this.request<LoginResponse, ErrorResponse>({
      path: `/users/session`,
      method: "POST",
      body: requestBody,
      secure: true,
      type: ContentType.Json,
      format: "json",
      ...params,
    });
  /**
   * @description delete a user
   *
   * @tags users
   * @name UsersDelete
   * @summary Delete a user
   * @request DELETE:/users/{userId}
   * @secure
   */
  usersDelete = (userId: string, params: RequestParams = {}) =>
    this.request<void, ErrorResponse>({
      path: `/users/${userId}`,
      method: "DELETE",
      secure: true,
      type: ContentType.Json,
      ...params,
    });
  /**
   * @description update a user
   *
   * @tags users
   * @name UsersPartialUpdate
   * @summary Update a user
   * @request PATCH:/users/{userId}
   * @secure
   */
  usersPartialUpdate = (userId: string, requestBody: UpdateUserRequest, params: RequestParams = {}) =>
    this.request<UserResponse, ErrorResponse>({
      path: `/users/${userId}`,
      method: "PATCH",
      body: requestBody,
      secure: true,
      type: ContentType.Json,
      format: "json",
      ...params,
    });
}
