/* eslint-disable */
/* tslint:disable */
/*
 * ---------------------------------------------------------------
 * ## THIS FILE WAS GENERATED VIA SWAGGER-TYPESCRIPT-API        ##
 * ##                                                           ##
 * ## AUTHOR: acacode                                           ##
 * ## SOURCE: https://github.com/acacode/swagger-typescript-api ##
 * ---------------------------------------------------------------
 */

export interface AutocompleteResponse {
  locations?: string[];
}

export interface Comparable {
  currency?: string;
  image?: string;
  link?: string;
  price?: number;
  source?: string;
  sourceLogo?: string;
  title?: string;
}

export enum Condition {
  ConditionGood = "good",
  ConditionBetter = "better",
  ConditionBest = "best",
}

export interface Container {
  createdAt?: string;
  createdBy?: string;
  deletedAt?: string;
  deletedBy?: string;
  id?: string;
  locationId?: string;
  name?: string;
  updatedAt?: string;
  updatedBy?: string;
}

export interface ContainerResponse {
  container?: Container;
}

export interface CreateContainerRequest {
  locationId?: string;
  name?: string;
}

export interface CreateCustomerRequest {
  email?: string;
  optInToMarketing?: boolean;
  phoneNumber?: string;
}

export interface CreateDealRequest {
  active?: boolean;
  brand?: string;
  link?: string;
  logo?: string;
  order?: number;
  orgId?: string;
  title?: string;
  visible?: boolean;
}

export interface CreateDonationRequest {
  /** @example "Flat Panel TV" */
  description?: string;
  geolocation?: Geolocation;
  items?: DonationItem[];
  loginQuery?: string;
}

export interface CreateLocationRequest {
  address?: string;
  city?: string;
  hours?: Hours;
  latitude?: number;
  longitude?: number;
  name?: string;
  orgId?: string;
  shopifyLocationId?: string;
  showInLocator?: boolean;
  state?: string;
  timezone?: string;
  zipCode?: string;
}

export interface CreateMarketingCampaignRequest {
  /** @example "Hello, world!" */
  message?: string;
  /** @example "thriftly" */
  orgId?: string;
  /** @example "2025-08-25" */
  sendDate?: string;
}

export interface CreateOrganizationRequest {
  ein?: string;
  email?: string;
  name?: string;
  priceConfig?: PriceAppConfig;
  shopifyConfig?: ShopifyConfig;
  styles?: LocationStyles;
}

export interface CreateSessionRequest {
  locationId?: string;
  orgId?: string;
  role?: UserRole;
}

export interface CreateUserRequest {
  email?: string;
  locationId?: string;
  name?: string;
  orgId?: string;
  password?: string;
  role?: UserRole;
}

export interface Customer {
  createdAt?: string;
  deletedAt?: string;
  email?: string;
  id?: string;
  optInToMarketing?: boolean;
  phoneNumber?: string;
  updatedAt?: string;
}

export interface CustomerResponse {
  customer?: Customer;
}

export interface CustomerWithLocation {
  createdAt?: string;
  deletedAt?: string;
  donationId?: string;
  email?: string;
  id?: string;
  location?: string;
  locationId?: string;
  optInToMarketing?: boolean;
  phoneNumber?: string;
  updatedAt?: string;
}

export interface Deal {
  active?: boolean;
  brand?: string;
  createdAt?: string;
  deletedAt?: string;
  id?: string;
  link?: string;
  logo?: string;
  order?: number;
  orgId?: string;
  title?: string;
  updatedAt?: string;
  visible?: boolean;
}

export interface DealResponse {
  deal?: Deal;
}

export interface DealsResponse {
  deals?: Deal[];
}

export interface Donation {
  /** @example "2020-01-01T00:00:00Z" */
  createdAt?: string;
  /** @example "550e8400-e29b-41d4-a716-446655440000" */
  customerId?: string;
  /** @example "2020-01-01T00:00:00Z" */
  deletedAt?: string;
  /** @example "Flat Panel TV" */
  description?: string;
  geolocation?: Geolocation;
  /** @example "550e8400-e29b-41d4-a716-446655440000" */
  id?: string;
  /** @example ["550e8400-e29b-41d4-a716-446655440000"," 550e8400-e29b-41d4-a716-446655440001"] */
  images?: string[];
  /** @example 1 */
  impactStoryViews?: number;
  items?: DonationItem[];
  location?: Location;
  /** @example "goodwillkynorthfield" */
  locationId?: string;
  loginQuery?: string;
  /** @example "This is a comment" */
  npsComment?: string;
  /** @example "2020-01-01T00:00:00Z" */
  npsResponseAt?: string;
  /** @example 10 */
  npsScore?: number;
  /** @example "demo" */
  organizationId?: string;
  /** @example 12345 */
  salesRepresenativeId?: number;
  /** @example "2020-01-01T00:00:00Z" */
  updatedAt?: string;
}

export interface DonationItem {
  /** @example "Electronics" */
  label?: DonationItemType;
  /** @example 99.99 */
  value?: number;
}

export enum DonationItemType {
  DonationItemTypeApparel = "Apparel",
  DonationItemTypeHomeGoods = "Home goods",
  DonationItemTypeElectronics = "Electronics",
  DonationItemTypeFurniture = "Furniture",
}

export interface DonationReport {
  count?: number;
  donations?: DonationWithCustomer[];
}

export interface DonationResponse {
  donation?: Donation;
}

export interface DonationTokenResponse {
  token?: string;
}

export interface DonationWithCustomer {
  createdAt?: string;
  /** @example "550e8400-e29b-41d4-a716-446655440000" */
  customerId?: string;
  deletedAt?: string;
  /** @example "Flat Panel TV" */
  description?: string;
  email?: string;
  geolocation?: Geolocation;
  id?: string;
  /** @example ["550e8400-e29b-41d4-a716-446655440000"," 550e8400-e29b-41d4-a716-446655440001"] */
  images?: string[];
  /** @example 1 */
  impactStoryViews?: number;
  items?: DonationItem[];
  location?: Location;
  /** @example "goodwillkynorthfield" */
  locationId?: string;
  loginQuery?: string;
  /** @example "This is a comment" */
  npsComment?: string;
  /** @example "2020-01-01T00:00:00Z" */
  npsResponseAt?: string;
  /** @example 10 */
  npsScore?: number;
  optInToMarketing?: boolean;
  /** @example "demo" */
  organizationId?: string;
  phoneNumber?: string;
  /** @example 12345 */
  salesRepresenativeId?: number;
  updatedAt?: string;
}

export interface DonorAnalyticsLocation {
  id?: string;
  impactStoryVideoViews?: number;
  location?: string;
  newContacts?: number;
  npsResponseCount?: number;
  npsScore?: number;
}

export interface DonorAnalyticsReport {
  donorsMultipleReceipts?: number;
  donorsNoReceipt?: number;
  donorsOneReceipt?: number;
  emailOptIn?: number;
  emailOptOut?: number;
  impactStoryVideoViews?: number;
  locations?: DonorAnalyticsLocation[];
  newMarketingContacts?: number;
  npsResponseCount?: number;
  npsResponses?: NpsResponse[];
  npsScore?: number;
  phoneOptIn?: number;
  phoneOptOut?: number;
  socialLogins?: number;
}

export interface DonorAnalyticsReportResponse {
  report?: DonorAnalyticsReport;
}

export interface DonorContactsResponse {
  contacts?: CustomerWithLocation[];
}

export interface ErrorResponse {
  error?: string;
}

export interface ErrorsResponse {
  errors?: string[];
}

export interface FeedbackRequest {
  comment?: string;
  feedback?: string;
}

export interface GeneratedProcessResponse {
  process?: Process;
  timeSaved?: number;
}

export interface Geolocation {
  /** @example 34.2736346 */
  latitude?: number;
  /** @example -118.6848109 */
  longitude?: number;
}

export interface Hours {
  fri?: string;
  mon?: string;
  sat?: string;
  sun?: string;
  thu?: string;
  tue?: string;
  wed?: string;
}

export interface Inventory {
  cartLink?: string;
  id?: string;
  link?: string;
  saleDate?: string;
  saleDiscounted?: boolean;
  salePrice?: number;
  source?: InventorySource;
}

export enum InventorySource {
  InventorySourceShopify = "shopify",
}

export interface ItemsRemainingResponse {
  itemsRemaining?: number;
}

export interface ItemsTodayResponse {
  itemsToday?: number;
}

export interface Location {
  address?: string;
  city?: string;
  createdAt?: string;
  deletedAt?: string;
  ein?: string;
  hours?: Hours;
  id?: string;
  latitude?: number;
  longitude?: number;
  name?: string;
  orgId?: string;
  shopifyLocationId?: string;
  showInLocator?: boolean;
  state?: string;
  styles?: LocationStyles;
  timezone?: string;
  updatedAt?: string;
  zipCode?: string;
}

export interface LocationResponse {
  location?: Location;
}

export interface LocationStyles {
  dealsPageDealSubtitle?: string;
  dealsPageDealTitle?: string;
  dealsPageIcon?: string;
  dealsPageSubtitle?: string;
  dealsPageTitle?: string;
  dealsPageVideoImageURL?: string;
  dealsPageVideoURL?: string;
  hidePoweredByThriftly?: boolean;
  locationSelectedPageButton?: string;
  locationSelectedPageSubtitle?: string;
  locationSelectedPageTitle?: string;
  logo?: string;
  logoDarkMode?: string;
  mapMarker?: string;
  primaryColor?: string;
  receiptPageDisclaimer?: string;
  secondaryColor?: string;
  sharingHashtag?: string;
  sharingLink?: string;
  sharingMessage?: string;
  startPageAllowEmailLogin?: boolean;
  startPageAllowPhoneLogin?: boolean;
  startPageLogo?: string;
  startPageOptIn?: string;
  startPageSubtitle?: string;
  startPageTitle?: string;
}

export interface LocationsResponse {
  locations?: Location[];
}

export interface LocatorResult {
  address?: string;
  city?: string;
  createdAt?: string;
  deletedAt?: string;
  distance?: number;
  ein?: string;
  hours?: string[];
  hoursToday?: string;
  id?: string;
  isOpen?: boolean;
  latitude?: number;
  logo?: string;
  longitude?: number;
  mapMarker?: string;
  name?: string;
  orgId?: string;
  shopifyLocationId?: string;
  showInLocator?: boolean;
  state?: string;
  styles?: LocationStyles;
  timezone?: string;
  updatedAt?: string;
  zipCode?: string;
}

export interface LocatorResultsResponse {
  locations?: LocatorResult[];
}

export interface LoginCustomerRequest {
  email?: string;
  locationId?: string;
  loginQuery?: string;
  optInToMarketing?: boolean;
  organizationId?: string;
  phoneNumber?: string;
  salesRepresentativeId?: number;
}

export interface LoginCustomerResponse {
  token?: string;
}

export interface LoginRequest {
  apiKey?: string;
  email?: string;
  orgId?: string;
  password?: string;
}

export interface LoginResponse {
  token?: string;
}

export interface MarketingCampaign {
  /** @example "2020-01-01T00:00:00Z" */
  createdAt?: string;
  /** @example "2020-01-01T00:00:00Z" */
  deletedAt?: string;
  /** @example "550e8400-e29b-41d4-a716-446655440000" */
  id?: string;
  /** @example "Hello, world!" */
  message?: string;
  /** @example "thriftly" */
  orgId?: string;
  /** @example "2020-01-01T00:00:00Z" */
  sendDate?: string;
  /** @example "2020-01-01T00:00:00Z" */
  updatedAt?: string;
}

export interface MarketingCampaignResponse {
  campaign?: MarketingCampaign;
}

export interface MarketingCampaignsResponse {
  campaigns?: MarketingCampaign[];
}

export interface NpsResponse {
  comment?: string;
  donatedAt?: string;
  email?: string;
  id?: string;
  location?: string;
  rating?: number;
  responseAt?: string;
}

export interface Organization {
  createdAt: string;
  deletedAt?: string;
  ein?: string;
  email?: string;
  id: string;
  name: string;
  priceAppConfig?: PriceAppConfig;
  shopifyConfig?: ShopifyConfig;
  styles?: LocationStyles;
  updatedAt?: string;
}

export interface OrganizationResponse {
  organization?: Organization;
}

export interface OrganizationStylesResponse {
  styles?: LocationStyles;
}

export interface OrganizationsResponse {
  organizations?: Organization[];
}

export interface PlaceProcessesRequest {
  processIds?: string[];
}

export interface PriceAppConfig {
  descriptionTemplate?: string;
  keepInStore?: boolean;
  printPriceLabelsDesktop?: boolean;
  printPriceLabelsMobile?: boolean;
  scanFixedPriceItemLabels?: boolean;
}

export interface PriceAppConfigResponse {
  priceAppConfig?: PriceAppConfig;
}

export enum PricingProcessor {
  ProcessorGemini = "gemini",
  ProcessorGoogleVision = "google_vision",
  ProcessorNone = "none",
  ProcessorOpenAI = "openai",
  ProcessorSerpApi = "serpapi",
}

export interface Process {
  accepted?: boolean;
  acceptedAt?: string;
  acceptedBy?: string;
  brand?: string;
  category?: string;
  color?: string;
  comparables?: Comparable[];
  condition?: Condition;
  containerId?: string;
  createdAt?: string;
  deletedAt?: string;
  feedback?: string;
  gender?: string;
  id?: string;
  images?: string[];
  inventory?: Inventory;
  keepInStore?: boolean;
  location?: string;
  locationId?: string;
  material?: string;
  notes?: string;
  orgId?: string;
  price?: string;
  processor?: PricingProcessor;
  quantity?: number;
  selectedComparable?: Comparable;
  size?: string;
  stationType?: StationType;
  tags?: Tag[];
  title?: string;
  updatedAt?: string;
  userId?: string;
  username?: string;
}

export interface ProcessResponse {
  process?: Process;
}

export interface ProcessesReport {
  count?: number;
  procsees?: Process[];
}

export interface ProcessesResponse {
  processes?: Process[];
}

export interface ProductionAnalyticsLocation {
  activeRepresentatives?: number;
  hardLineItemsProduced?: number;
  id?: string;
  name?: string;
  softLineItemsProduced?: number;
}

export interface ProductionAnalyticsReport {
  activeRepresentatives?: number;
  hardLineItemsProduced?: number;
  locations?: ProductionAnalyticsLocation[];
  representatives?: ProductionAnalyticsRepresentative[];
  softLineItemsProduced?: number;
}

export interface ProductionAnalyticsReportResponse {
  report?: ProductionAnalyticsReport;
}

export interface ProductionAnalyticsRepresentative {
  hardLineItemsProduced?: number;
  id?: string;
  location?: string;
  name?: string;
  softLineItemsProduced?: number;
}

export interface RecoverPasswordRequest {
  email?: string;
}

export interface ResetPasswordRequest {
  password?: string;
  recoveryToken?: string;
}

export interface SellThroughAnalyticsLocation {
  averageTimeOnShelf?: number;
  id?: string;
  name?: string;
  sellThrough?: number;
  soldAtDiscount?: number;
}

export interface SellThroughAnalyticsReport {
  averageTimeOnShelf?: number;
  locations?: SellThroughAnalyticsLocation[];
  representatives?: SellThroughAnalyticsRepresentative[];
  sellThrough?: number;
  soldAtDiscount?: number;
}

export interface SellThroughAnalyticsReportResponse {
  report?: SellThroughAnalyticsReport;
}

export interface SellThroughAnalyticsRepresentative {
  averageTimeOnShelf?: number;
  id?: string;
  location?: string;
  locationId?: string;
  name?: string;
  sellThrough?: number;
  soldAtDiscount?: number;
}

export interface ShareDonationRequest {
  /** @example "email@example.com" */
  emailAddress?: string;
  /** @example "555-555-5555" */
  phoneNumber?: string;
}

export interface ShopifyConfig {
  accessToken?: string;
  scopes?: string;
  shopName?: string;
}

export enum StationType {
  StationTypeSoftline = "soft_line",
  StationTypeHardline = "hard_line",
}

export interface Tag {
  name?: string;
  score?: number;
}

export interface UpdateCustomerRequest {
  email?: string;
  optInToMarketing?: boolean;
  phoneNumber?: string;
}

export interface UpdateDealRequest {
  active?: boolean;
  brand?: string;
  link?: string;
  logo?: string;
  order?: number;
  orgId?: string;
  title?: string;
  visible?: boolean;
}

export interface UpdateDonationRequest {
  /** @example "Flat Panel TV" */
  description?: string;
  items?: DonationItem[];
  /** @example "goodwillky-northfield" */
  locationId?: string;
}

export interface UpdateLocationRequest {
  address?: string;
  city?: string;
  hours?: Hours;
  latitude?: number;
  longitude?: number;
  name?: string;
  orgId?: string;
  shopifyLocationId?: string;
  showInLocator?: boolean;
  state?: string;
  timezone?: string;
  zipCode?: string;
}

export interface UpdateOrganizationRequest {
  ein?: string;
  email?: string;
  name?: string;
  priceAppConfig?: PriceAppConfig;
  shopifyConfig?: ShopifyConfig;
  styles?: LocationStyles;
}

export interface UpdateProcessRequest {
  accepted?: boolean;
  acceptedAt?: string;
  acceptedBy?: string;
  brand?: string;
  category?: string;
  color?: string;
  condition?: Condition;
  containerId?: string;
  feedback?: string;
  gender?: string;
  images?: string[];
  inventory?: Inventory;
  keepInStore?: boolean;
  material?: string;
  notes?: string;
  price?: string;
  quantity?: number;
  selectedComparable?: Comparable;
  size?: string;
  title?: string;
}

export interface UpdateUserRequest {
  email?: string;
  locationId?: string;
  name?: string;
  orgId?: string;
  password?: string;
  role?: UserRole;
}

export interface User {
  createdAt: string;
  deletedAt?: string;
  email?: string;
  id: string;
  /** For pricers */
  locationId?: string;
  name: string;
  orgId?: string;
  role: UserRole;
  updatedAt?: string;
}

export interface UserResponse {
  user?: User;
}

export enum UserRole {
  RoleSystem = "system",
  RoleAdmin = "admin",
  RoleLister = "lister",
  RolePricer = "pricer",
}

export interface UsersResponse {
  users?: User[];
}
