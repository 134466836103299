import { useContext, useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { AxiosError } from "axios";
import { MdRefresh } from "react-icons/md";
import Camera, { CameraRef } from "../../components/common/Camera";
import { ErrorResponse, PricingProcessor, StationType, UserRole } from "../../generated/data-contracts";
import Header from "../../components/header/Header";
import { ApiContext } from "../../components/context/ApiContext";
import Itembadge from "../../components/header/ItemBadge";
import MenuPopover from "../../components/header/MenuPopover";
import { ROUTES } from "../../constants";
import { getStationType, setPriceAppConfig } from "../../utils/localStorage";
import { convertUserImage } from "../../utils/camera";
import { Button } from "../../components/common/Button";
import { OrgContext } from "../../components/context/OrgContext";
import {
  getStationType as getStationTypeLocalStorage,
  setStationType as setStationTypeLocalStorage,
} from "../../utils/localStorage";
import { isMobile } from 'react-device-detect';
import BarcodeListener from "../../components/BarcodeListener";
import Loader from "../../components/common/Loader";
import { canPrint } from "../../utils/printers";
import { isSet } from "../../utils/validation";

export default function PricerPage() {
  const { VITE_API_BASE_URL } = import.meta.env;
  const { orgId, role, email } = useContext(OrgContext);
  const navigate = useNavigate();
  const { processes: processApi, organizations: organizationsApi } = useContext(ApiContext);
  const [stationType, setStationType] = useState(getStationTypeLocalStorage());

  const [itemsCount, setItemsCount] = useState<number>();
  const printerLoaded = useRef(false);
  const selectedPrinter = useRef<any>();
  const [lastPriceLabelPrinted, setLastPriceLabelPrinted] = useState<string>();

  const cameraRef = useRef<CameraRef>(null);
  const mobileCameraRef = useRef<HTMLInputElement>(null);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState<string>("");

  const getItemsCount = () => {
    processApi
      ?.itemsTodayList()
      .then((resp) => {
        setItemsCount(resp.data.itemsToday);
      })
      .catch((err: AxiosError) => {
        console.error((err.response?.data as ErrorResponse)?.error || "An error occurred. Please try again.");
      });
  }

  useEffect(() => {
    organizationsApi?.priceAppConfigDetail(orgId).then((response) => {
      if (response.data.priceAppConfig) setPriceAppConfig(response.data.priceAppConfig);

      // get default label printer
      if (canPrint() && !printerLoaded.current) {
        printerLoaded.current = true;
        (window as any).BrowserPrint.getDefaultDevice("printer", function (printer: any) {
          selectedPrinter.current = printer;
        }, function (error: string) {
          toast(`Error getting default printer: ${error}`);
        })
      }
    });

    getItemsCount();

    // open camera automtically on mobile when page loads
    if (isMobile) mobileCameraRef?.current?.click()
  }, [1]);

  const getProcessor = (price?: string) => {
    if (isSet(price)) return PricingProcessor.ProcessorNone;
    if (getStationType() === StationType.StationTypeHardline) return PricingProcessor.ProcessorSerpApi
    return PricingProcessor.ProcessorNone;
  }

  const getBody = () => {
    if (isMobile) {
      if (isLoading) {
        return <div className="w-full">
          <Loader />
        </div>
      }
      return (
        <div className=" w-full flex flex-col gap-4 p-8">
          <Button
            textColor="text-thriftlyGreyDark"
            backgroundColor="bg-thriftlyOffWhite"
            className={`w-full h-32 border-thriftlyGrey text-2xl`}
            onClick={() => { mobileCameraRef?.current?.click() }}
          >
            Take picture
          </Button>
          {error && <p className="text-md text-center text-red-500">{error}</p>}
          <input
            ref={mobileCameraRef}
            style={{ display: "none" }}
            type="file"
            accept="image/*"
            capture="environment"
            onChange={(e) => {
              if (e.target.files === null) {
                alert("No file selected");
                return;
              }

              // upload image
              setIsLoading(true);
              processApi
                ?.processesCreate({
                  image: new File([
                    e.target.files[0]
                  ], "image", {
                    type: "image/png",
                  }),
                }, {
                  processor: getProcessor(),
                  stationType: getStationType(),
                })
                .then(async (resp) => {
                  navigate(`${ROUTES.PROCESS_PAGE}/${resp?.data?.process?.id}`);
                })
                .catch((err: AxiosError) => {
                  setError((err.response?.data as ErrorResponse)?.error || "An error occurred. Please try again.");
                })
                .finally(() => { setIsLoading(false) });
            }}
          />
        </div>
      )
    }

    return (
      <Camera
        ref={cameraRef}
        onImageCapture={async (
          userImage: string,
          setCameraIsLoading: (isLoading: boolean) => void,
          closeCamera: () => void,
          clearImage: () => void,
          price?: string,
          condition?: string,
          category?: string,
          gender?: string,
        ) => {
          setCameraIsLoading(true);
          setError("");

          // upload image
          await processApi
            ?.processesCreate({
              image: convertUserImage(userImage),
            }, {
              price,
              condition,
              category,
              gender,
              processor: getProcessor(price),
              stationType: getStationType(),
            })
            .then(async (resp) => {
              setCameraIsLoading(false);
              // when rapid pricing, print a price label immediately. Otherwise proceed to process page
              if (isSet(price) && resp.data.process?.processor === PricingProcessor.ProcessorNone) {
                clearImage();
                getItemsCount();
                toast.success("Item successfully priced");
                setLastPriceLabelPrinted(resp.data.process?.id);

                const url = `${VITE_API_BASE_URL}/processes/${resp.data.process?.id}/label?print=true`;
                selectedPrinter.current.sendFile(url, undefined, (error: any) => {
                  toast(`Error printing label: ${error}`);
                  console.error(`Error printing label: ${error}`);
                });
              } else {
                closeCamera();
                navigate(`${ROUTES.PROCESS_PAGE}/${resp?.data?.process?.id}`);
              }
            })
            .catch((err: AxiosError) => {
              setError((err.response?.data as ErrorResponse)?.error || "An error occurred. Please try again.");
            })
            .finally(() => { setCameraIsLoading(false) });
        }}
      >
        <div className="flex flex-col px-4">
          <Button
            textColor="text-thriftlyGreyDark"
            backgroundColor="bg-thriftlyOffWhite"
            className={`w-full h-32 border-thriftlyGrey text-2xl`}
            onClick={() => { cameraRef?.current?.capture(undefined) }}
          >
            Search
          </Button>
          {error && <p className="text-md text-center text-red-500">{error}</p>}
          <BarcodeListener onFixedPriceScan={(scannerId, price, condition, category, gender) => {
            if (isLoading) return;
            cameraRef?.current?.capture(price, condition, category, gender)
          }} />
        </div>
      </Camera>
    )
  }

  return (
    <div className="h-dvh overscroll-none overflow-y-clip flex flex-col">
      <Header
        darkMode
        left={<div className="flex flex-row gap-4 items-center pl-4">
          <MenuPopover role={role as UserRole} darkMode />
          {!isMobile && (
            <div className="flex items-center justify-end h-[20px] shrink-0">
              <div
                className="rounded text-xs uppercase py-1 px-2 bg-thriftlyGreyDark text-thriftlyOffWhite cursor-pointer"
                onClick={() => {
                  const newStationType = stationType === StationType.StationTypeHardline ?
                    StationType.StationTypeSoftline :
                    StationType.StationTypeHardline;
                  setStationType(newStationType);
                  setStationTypeLocalStorage(newStationType);
                }}>
                {stationType === StationType.StationTypeHardline ? "HARD GOODS" : "SOFT GOODS"}
              </div>
            </div>
          )}
        </div>}
        right={<div className="flex flex-row gap-4 justify-end items-center pr-4">
          {!isMobile && (<div className="text-thriftlyOffWhite">{email}</div>)}
          <Itembadge role={role as UserRole} itemsCount={itemsCount} />
        </div>}
      />
      <div className="flex flex-col h-full w-full border-t-2 border-thriftlyGreyDark items-center justify-center ">
        {getBody()}
      </div>
      {lastPriceLabelPrinted && (
        <div className="absolute bottom-0 right-0 flex flex-row mt-auto p-4">
          <Button
            className="ml-auto mt-auto"
            onClick={() => {
              const url = `${VITE_API_BASE_URL}/processes/${lastPriceLabelPrinted}/label?print=true`;
              selectedPrinter.current.sendFile(url, undefined, (error: any) => {
                toast(`Error printing label: ${error}`);
                console.error(`Error printing label: ${error}`);
              });
            }}>
            <MdRefresh />Reprint label
          </Button>
        </div>
      )}
    </div>
  );
}
