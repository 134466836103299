import React from "react";

export default function ToggleIcon({
    height,
    defaultColor,
    icon,
    onClick,
    toggled,
    toggledColor,
}: {
    defaultColor: string;
    height: number | string;
    icon: React.FunctionComponent<React.SVGProps<SVGSVGElement>>;
    onClick: () => void;
    toggled: boolean;
    toggledColor: string;
}) {
    const fill = toggled ? toggledColor : defaultColor;
    const bg = toggled ? defaultColor : toggledColor;
    const iconInstance = React.createElement(icon, { height, fill }, null);

    return (
        <div
            className={`flex justify-center items-center rounded-full overflow-hidden bg-${bg}`}
            onClick={() => { onClick(); }}
        >
            {iconInstance}
        </div>
    );
}
