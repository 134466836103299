import { useEffect, useState } from "react";
import { useSearchParams } from "react-router-dom";
import { AxiosError } from "axios";
import { ErrorResponse, Organization, OrganizationResponse, Process, ProcessesResponse } from "../../generated/data-contracts";
import formatDateOnly from "../../utils/date";
import { Organizations } from "../../generated/Organizations";

export default function PullListPage() {
  const { VITE_API_BASE_URL } = import.meta.env;
  const [searchParams] = useSearchParams();
  const [organization, setOrganization] = useState<Organization>();
  const [processes, setProcesses] = useState<Process[]>();
  const [error, setError] = useState<string>("");

  const token = searchParams.get("token");
  const org = searchParams.get("org_id");
  const date = searchParams.get("date") ? new Date(searchParams.get("date")!) : new Date();

  useEffect(() => {
    if (!org || token === null) {
      console.error("Organization ID and token is required.");
      setError("Organization ID and token is required.");
      return;
    }

    const organizationsApi = new Organizations({
      baseURL: `${VITE_API_BASE_URL}`,
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });

    organizationsApi
      ?.organizationsDetail(org)
      .then(({ data }: { data: OrganizationResponse }) => {
        setOrganization(data.organization);
      })
      .catch((err: AxiosError) => {
        console.error((err.response?.data as ErrorResponse)?.error || "An error occurred. Please try again.");
        setError((err.response?.data as ErrorResponse)?.error || "An error occurred. Please try again.");
      });

    organizationsApi
      ?.pullListDetail(org, { date: formatDateOnly(date) })
      .then(({ data }: { data: ProcessesResponse }) => {
        setProcesses(data.processes);
      })
      .catch((err: AxiosError) => {
        console.error((err.response?.data as ErrorResponse)?.error || "An error occurred. Please try again.");
        setError((err.response?.data as ErrorResponse)?.error || "An error occurred. Please try again.");
      });
  }, [1]);

  const emptyCells = () => {
    const cells = processes?.length ? 4 - processes.length % 4 : 4;
    return new Array(cells).fill(null).map((_, idx: number) => (
      <tr className="h-[185px] break-inside-avoid break-after-auto" key={idx} />
    ));
  }

  if (error) {
    return (
      <div className="bg-thriftlyOffWhite h-screen">
        <div className="flex flex-col justify-center items-center h-full">
          <div className="text-3xl text-slate-900">Error generating pull list: {error}</div>
        </div>
      </div>
    );
  }

  return (
    <div className="bg-thriftlyOffWhite h-full">
      <table className="w-full border-collapse break-inside-auto">
        <thead>
          <tr>
            <td colSpan={5}>
              <div className="flex flex-row justify-between align-middle items-center w-full">
                <div className="text-3xl text-slate-900">{date.toLocaleDateString().replace(/\//g, '-')} Pull List</div>
                <img className="h-[48px]" src={organization?.styles?.logo} alt="logo" />
              </div>
              <hr className="border border-slate-500 mt-4 mb-10" />
            </td>
          </tr>
        </thead>
        <thead>
          <tr>
            <td className="border border-slate-500 text-center">#</td>
            <td className="border border-slate-500 text-left pl-2">Location</td>
            <td className="border border-slate-500 text-left pl-2">Picture</td>
            <td className="border border-slate-500 text-left pl-2">Title</td>
            <td className="border border-slate-500 text-left pl-2">Feedback</td>
          </tr>
        </thead>
        <tbody>
          {processes?.map((process: Process, idx: number) =>
            <tr className="h-[185px] break-inside-avoid break-after-auto" key={process.id}>
              <td className="border border-slate-500 text-center p-1">{idx + 1}</td>
              <td className="border border-slate-500 pl-2">{process.locationId}</td>
              <td className="border border-slate-500 p-2">{process.images ? <img src={process.images[0]} alt="item image" /> : null}</td>
              <td className="border border-slate-500 pl-2">{process.title}</td>
              <td className="border border-slate-500 pl-2 min-w-[100px]">{process.feedback}</td>
            </tr>
          )}
          {emptyCells()}
        </tbody>
        <tfoot>
          <tr>
            <td colSpan={5}>
              <hr className="border border-slate-500 mt-10 mb-4" />
              <div className="flex flex-row justify-between align-middle w-full">
                <div className="flex flex-row gap-2 text-xl text-slate-900">
                  <div className="font-medium align-top ">powered by</div>
                  <img src="https://images.thriftly.com/thriftly-logo.png" className="h-[30px]" alt="logo" />
                </div>
                <div>Total items: {processes?.length || "0"}</div>
              </div>
            </td>
          </tr>
        </tfoot>
      </table>
    </div>
  );
}
