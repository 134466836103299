import { createContext } from "react";

type OrgContextType = {
  userId: string;
  orgId: string;
  authenticated: boolean;
  role: string;
  email: string;
  token: string | null;
  setOrgContext: () => void;
};

export const OrgContext = createContext<OrgContextType>({
  userId: "",
  orgId: "",
  authenticated: false,
  role: "",
  email: "",
  token: "",
  setOrgContext: () => { },
});
