import * as EmailValidator from 'email-validator';

export function validateEmail(email?: string): boolean {
  if (email === undefined || email === "") return false;

  return EmailValidator.validate(email);
}

export function validatePassword(password?: string): boolean {
  if (password === undefined || password === "") return false;

  return password.length >= 8;
}

export function isSet(value?: string): boolean {
  return value !== undefined && value !== null && value !== "";
}