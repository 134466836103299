import { useCallback, useEffect, useState } from "react";
import { Outlet, useLocation, useNavigate } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import "./App.css";
import { ApiContext } from "./components/context/ApiContext";
import { OrgContext } from "./components/context/OrgContext";
import { getInfoFromSessionToken, getInfoFromToken, isTokenExpired } from "./utils/token";
import { Users } from "./generated/Users";
import { ROUTES, SESSION_KEY } from "./constants";
import { Processes } from "./generated/Processes";
import { Organizations } from "./generated/Organizations";
import { Containers } from "./generated/Containers";
import { Locations } from "./generated/Locations";

function App() {
  const { VITE_API_BASE_URL } = import.meta.env;
  const location = useLocation();
  const navigate = useNavigate();

  const getToken = () => {
    if (localStorage.getItem(SESSION_KEY) !== null) {
      return getInfoFromSessionToken();
    }

    return getInfoFromToken();
  }

  const [tokenInfo, setTokenInfo] = useState(getToken);

  const refreshTokenInfo = useCallback(() => {
    setTokenInfo(getToken());

    // Log out if token is expired, checking every second
    setInterval(() => { if (isTokenExpired()) navigate(ROUTES.LOGOUT); }, 1000);
  }, []);

  // Log out if token is expired
  useEffect(() => {
    if (isTokenExpired()) navigate(ROUTES.LOGOUT);
  }, [tokenInfo, location]);

  // Log out if token is expired
  useEffect(() => {
    if (isTokenExpired()) navigate(ROUTES.LOGOUT);
  }, [tokenInfo, location]);

  return (
    <ApiContext.Provider
      value={{
        login: new Users({
          baseURL: `${VITE_API_BASE_URL}`,
          headers: {
            Authorization: `Bearer ${tokenInfo.token}`,
          },
        }),
        // Use session token if exists
        organizations: new Organizations({
          baseURL: `${VITE_API_BASE_URL}`,
          headers: {
            Authorization: `Bearer ${tokenInfo.token}`,
          },
        }),
        processes: new Processes({
          baseURL: `${VITE_API_BASE_URL}`,
          headers: {
            Authorization: `Bearer ${tokenInfo.session || tokenInfo.token}`,
          },
        }),
        containers: new Containers({
          baseURL: `${VITE_API_BASE_URL}`,
          headers: {
            Authorization: `Bearer ${tokenInfo.session || tokenInfo.token}`,
          },
        }),
        locations: new Locations({
          baseURL: `${VITE_API_BASE_URL}`,
          headers: {
            Authorization: `Bearer ${tokenInfo.session || tokenInfo.token}`,
          },
        }),
      }}
    >
      <OrgContext.Provider
        value={{ ...tokenInfo, setOrgContext: refreshTokenInfo }}
      >
        <Outlet />
        <ToastContainer
          position="top-right"
          autoClose={3000}
          hideProgressBar={false}
          newestOnTop={false}
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable
          pauseOnHover
        />
      </OrgContext.Provider>
    </ApiContext.Provider>
  );
}

export default App;
