import { AxiosError } from "axios";
import { useContext, useRef } from "react";
import Camera, { CameraRef } from "../../../components/common/Camera";
import { ApiContext } from "../../../components/context/ApiContext";
import { ErrorResponse, Process, ProcessResponse } from "../../../generated/data-contracts";
import { convertUserImage } from "../../../utils/camera";
import { Button } from "../../../components/common/Button";

export default function CameraModal({ process, onHide }: {
    process: Process;
    onHide: (process: Process) => void;
}) {
    const { processes: processApi } = useContext(ApiContext);
    const cameraRef = useRef<CameraRef>(null);

    return (
        <Camera
            ref={cameraRef}
            onImageCapture={async (
                userImage: string,
                setIsLoading: (isLoading: boolean) => void,
                setError: (error: string) => void,
                closeCamera: () => void,
            ) => {
                setIsLoading(true);

                // upload image
                await processApi
                    ?.imageCreate(process.id!, {
                        image: convertUserImage(userImage),
                    })
                    .then(({ data }: { data: ProcessResponse }) => {
                        onHide(data.process!);
                    })
                    .catch((err: AxiosError) => {
                        setIsLoading(false);
                        setError((err.response?.data as ErrorResponse)?.error || "An error occurred. Please try again.");
                    }).finally(() => { setIsLoading(false) });
            }}
        >
            <Button
                textColor="text-thriftlyGreyDark"
                backgroundColor="bg-thriftlyOffWhite"
                className={`w-full h-32 border-thriftlyGrey text-2xl`}
                onClick={() => { cameraRef?.current?.capture(undefined) }}
            >
                Take picture
            </Button>
        </Camera>
    );
}
