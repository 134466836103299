export const TOKEN_KEY = "jwttoken";
export const SESSION_KEY = "jwtsession";
export const RECOVERY_TOKEN_KEY = "token";
export const ORG_STYLES_KEY = "orgStyles";
export const PRICE_APP_CONFIG_KEY = "priceAppConfig";
export const USER_SESSIONS_KEY = "userSessions";

export enum ROUTES {
  HOME = "/",
  LOGIN = "/login",
  LOGOUT = "/logout",
  FORGOT_PASSWORD = "/forgot-password",
  PASSWORD_RECOVERY = "/password-recovery",
  PRODUCTION_PAGE = "/production",
  PRICER_PAGE = "/pricer",
  PROCESS_PAGE = "/process",
  LISTER_PAGE = "/lister",
  PULL_LIST_PAGE = "/pull-list",
  PLACE_PAGE = "/place",
  CONTAINERS_PAGE = "/containers",
  SETTINGS_PAGE = "/settings",
  INVENTORY_PAGE = "/inventory",
  SHOP_PAGE = "/shop",
}
