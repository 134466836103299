import React, { forwardRef, ReactNode, useEffect, useImperativeHandle, useRef, useState } from "react";
import Webcam from "react-webcam";
import { Button } from "./Button";
import useCameraPermission from "../../hooks/useCameraPermissions";
import { getCameraFormat, getCameraResolution, getCameraRotation } from "../../utils/localStorage";
import { rotate } from "../../utils/camera";
import Loader from "./Loader";
import { isMobile } from "react-device-detect";

export interface CameraRef {
  capture(price?: string, condition?: string, category?: string, gender?: string): void;
}

export default forwardRef(function Camera({
  children,
  onImageCapture,
}: {
  children?: ReactNode;
  onImageCapture: (
    userImage: string,
    setIsLoading: (isLoading: boolean) => void,
    closeCamera: () => void,
    clearImage: () => void,
    price?: string,
    condition?: string,
    category?: string,
    gender?: string,
  ) => void;
}, ref) {
  const cameraPermission = useCameraPermission();
  const [userImage, setUserImage] = useState<string | null>(null);
  const webcamRef = useRef<Webcam>(null);

  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState("");

  useImperativeHandle(
    ref,
    () => ({
      capture(price?: string, condition?: string, category?: string, gender?: string) {
        capture(price, condition, category, gender)
      }
    }),
  )

  useEffect(() => {
    if (error !== "") {
      setUserImage(null);
    }
  }, [error]);

  const width = function () {
    if (isMobile) {
      switch (getCameraFormat()) {
        case "portrait":
          return "w-3/4";
        case "landscape":
          return "w-full";
        case "square":
        default:
          return "w-full";
      }
    };

    switch (getCameraFormat()) {
      case "portrait":
        return "w-1/5";
      case "landscape":
        return "w-1/2";
      case "square":
      default:
        return "w-1/3";
    }
  }();

  const cameraFormat = (function () {
    if (isMobile) {
      switch (getCameraFormat()) {
        case "portrait":
          return 3 / 2;
        case "landscape":
          return 9 / 16;
        case "square":
        default:
          return 1;
      }
    }

    switch (getCameraFormat()) {
      case "portrait":
        return 2 / 3;
      case "landscape":
        return 16 / 9;
      case "square":
      default:
        return 1;
    }
  })();

  const cameraResolution = (function () {
    switch (getCameraResolution()) {
      case "720p":
        switch (getCameraFormat()) {
          case "portrait":
            return { width: 480, height: 720 };
          case "landscape":
            return { width: 1280, height: 720 };
          case "square":
          default:
            return { width: 720, height: 720 };
        }
      case "480p":
        switch (getCameraFormat()) {
          case "portrait":
            return { width: 320, height: 480 };
          case "landscape":
            return { width: 640, height: 480 };
          case "square":
          default:
            return { width: 480, height: 480 };
        }
      case "1080p":
      default:
        switch (getCameraFormat()) {
          case "portrait":
            return { width: 720, height: 1080 };
          case "landscape":
            return { width: 1920, height: 1080 };
          case "square":
          default:
            return { width: 1080, height: 1080 };
        }
    }
  })();

  const videoConstraints = function () {
    return {
      facingMode: "environment",
      aspectRatio: cameraFormat,
    }
  }();

  const closeCamera = () => {
    if (webcamRef.current && webcamRef.current.stream) {
      webcamRef.current.stream.getTracks().forEach((track) => track.stop());
    }
  };

  const clearImage = () => {
    setUserImage(null);
  }

  const capture = React.useCallback(async (price?: string, condition?: string, category?: string, gender?: string) => {
    if (webcamRef.current && webcamRef.current.stream) {
      const imageSrc = webcamRef.current.getScreenshot(cameraResolution);

      rotate(imageSrc!, Number(getCameraRotation())).then((rotatedImageSrc) => {
        setUserImage(rotatedImageSrc);
        onImageCapture(rotatedImageSrc!, setIsLoading, closeCamera, clearImage, price, condition, category, gender)
      });
    }
  }, [webcamRef, setUserImage]);

  if (cameraPermission !== "granted") {
    return (
      <div className={`${width} flex flex-col items-center justify-center`}>
        <div className="w-full inline-block flex flex-col gap-4 p-4">
          <div className="w-full flex justify-center items-center">
            <Loader />
          </div>
          <div className="w-full p-4">
            <Button
              className="w-full"
              onClick={() => window.location.reload()}
            >
              Allow camera permission
            </Button>
          </div>
        </div>
      </div>
    );
  }

  return (
    <div className={`${width} h-full flex flex-col items-center`}>
      <div className="w-full rounded-thriftlyCamera overflow-hidden">
        {!!userImage && (
          <div className={`${width} absolute inline-block flex flex-col gap-4 p-4`}>
            <img
              className={`z-50 rounded-thriftlyCamera overflow-hidden`}
              src={userImage || ""}
              alt="Donation"
            />
          </div>
        )}
        <div className="w-full inline-block flex flex-col gap-4 p-4">
          <Webcam
            audio={false}
            className={`w-full ${"transform rotate-"}${getCameraRotation()} rounded-thriftlyCamera overflow-hidden`}
            ref={webcamRef}
            screenshotFormat="image/png"
            mirrored={false}
            screenshotQuality={1}
            imageSmoothing={false}
            videoConstraints={videoConstraints}
          />
        </div>
        {isLoading ? (
          <div className="flex justify-center">
            <Loader />
          </div>
        ) : (
          <>{children}</>
        )}
      </div>
    </div>
  );
});
