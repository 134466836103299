import {
  createBrowserRouter,
  createRoutesFromElements,
  Route,
} from "react-router-dom";
import App from "./App";
import { ROUTES } from "./constants";
import { ProtectedRoute } from "./components/route/ProtectedRoute";
import LoginPage from "./pages/LoginPage";
import LogoutPage from "./pages/LogoutPage";
import ForgotPasswordPage from "./pages/ForgotPasswordPage";
import PasswordRecoveryPage from "./pages/PasswordRecoveryPage";
import ShopPage from "./pages/ShopPage/ShopPage";
import HomePage from "./pages/HomePage/HomePage";
import ProductionPage from "./pages/ProductionPage/ProductionPage";
import PricerPage from "./pages/PricerPage/PricerPage";
import ListerPage from "./pages/ListerPage/ListerPage";
import ProcessPageLoader from "./pages/ProcessPage/ProcessPageLoader";
import PlacePage from "./pages/PlacePage/PlacePage";
import ContainersPage from "./pages/ContainersPage/ContainersPage";
import SettingsPage from "./pages/SettingsPage/SettingsPage";
import InventoryPage from "./pages/InventoryPage/InventoryPage";
import PullListPage from "./pages/PullListPage/PullListPage";

const Router = createBrowserRouter(
  createRoutesFromElements(
    <Route path={ROUTES.HOME} element={<App />}>
      {/* PUBLIC */}
      <Route path={ROUTES.LOGIN} element={<LoginPage />} />
      <Route path={ROUTES.LOGOUT} element={<LogoutPage />} />
      <Route path={ROUTES.FORGOT_PASSWORD} element={<ForgotPasswordPage />} />
      <Route path={ROUTES.PASSWORD_RECOVERY} element={<PasswordRecoveryPage />} />
      <Route path={`${ROUTES.SHOP_PAGE}/:id`} element={<ShopPage />} />

      {/* AUTHENTICATED */}
      <Route element={<ProtectedRoute />}>
        <Route index element={<HomePage />} />
        <Route path={ROUTES.PRODUCTION_PAGE} element={<ProductionPage />} />
        <Route path={ROUTES.PRICER_PAGE} element={<PricerPage />} />
        <Route path={ROUTES.LISTER_PAGE} element={<ListerPage />} />
        <Route path={`${ROUTES.PROCESS_PAGE}/:id`} element={<ProcessPageLoader />} />
        <Route path={ROUTES.PLACE_PAGE} element={<PlacePage />} />
        <Route path={ROUTES.CONTAINERS_PAGE} element={<ContainersPage />} />
        <Route path={ROUTES.SETTINGS_PAGE} element={<SettingsPage />} />
        <Route path={ROUTES.INVENTORY_PAGE} element={<InventoryPage />} />
      </Route>

      {/* SELF-AUTHENTICATED */}
      <Route path={ROUTES.PULL_LIST_PAGE} element={<PullListPage />} />

      <Route path="*" element={<HomePage />} />
    </Route>,
  ),
);

export default Router;
