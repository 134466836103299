import { MdOpenInNew, MdSearch } from "react-icons/md";
import { Comparable } from "../../../generated/data-contracts";

export default function ComparableTileDesktop(props: {
    comparable: Comparable;
    selected?: boolean;
    onClick?: (comparable: Comparable, event: React.MouseEvent<HTMLDivElement, MouseEvent>) => void;
    onClickImage?: (comparable: Comparable) => void;
}) {
    const { comparable, selected, onClick, onClickImage } = props;

    return (
        <div
            className={`w-[250px] h-[378px] flex flex-col cursor-pointer gap-2 text-thriftlyOffWhite rounded-thriftlyTile ${selected ? "bg-thriftlyGreyDark border-2 border-thriftlyOffWhite shadow-thriftlyTile" : "border border-thriftlyGrey"}`}
            onClick={(e) => onClick ? onClick(comparable, e) : null}
        >
            <div className="relative flex bg-thriftlyGreyDark rounded-thriftlyCamera overflow-hidden items-end justify-end m-2">
                <div
                    className="absolute flex h-[48px] w-[48px] items-center justify-center bg-thriftlyGreyDark rounded overflow-hidden rounded-full cursor-pointer border-2 border-white m-2"
                    onClick={(e) => { e.stopPropagation(); onClickImage ? onClickImage(comparable) : null }}>
                    <MdSearch fill="#ffffff" className="h-[32px] w-[32px]" />
                </div>
                <img className="h-[240px] w-[240px] object-contain" src={comparable.image} alt="comparable" />
            </div>
            <div className="flex flex-col px-2">
                <div className="text-xl line-clamp-1">{comparable.title}</div>
                <div className="text-xl line-clamp-1 text-thriftlyGreyLight">{comparable.price ? comparable?.currency + comparable.price.toFixed(2) : ''}</div>
            </div>
            <div
                className="flex flex-row gap-4 items-center justify-between mt-auto bg-thriftlyGreyDark rounded-b-thriftlyTile overflow-hidden px-4 py-2"
                onClick={(e) => { e.stopPropagation(); window.open(comparable.link, "_blank"); }}
            >
                <div className="text-xl lowercase line-clamp-1">{comparable.source}</div>
                <div className="flex flex-row shrink-0 items-center justify-center h-[25px] w-[25px] cursor-pointer">
                    <div className="flex items-center justify-center bg-opacity-10 rounded h-[24px] w-[24px]">
                        <MdOpenInNew fill="#ffffff" className="h-[24px] w-[24px]" />
                    </div>
                </div>
            </div>
        </div>
    );
}
