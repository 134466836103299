import { HTMLInputTypeAttribute, ReactNode } from "react";
import { SearchIcon } from '@shopify/polaris-icons';

type InputProps = {
  className?: string;
  textColor?: string;
  backgroundColor?: string;
  disabled?: boolean;
  label?: string;
  placeholder: string;
  onChange?: (value: string) => void;
  onBlur?: (value: string) => void;
  onEnter?: (value: string) => void;
  type?: HTMLInputTypeAttribute;
  value?: string;
  allowedCharacters?: string;
  maxLength?: number;
  prefix?: ReactNode;
  suffix?: ReactNode;
};

export default function CustomInputText(props: InputProps) {
  const {
    className,
    textColor,
    backgroundColor,
    disabled,
    label,
    value,
    placeholder,
    onChange,
    onBlur,
    onEnter,
    type,
    allowedCharacters,
    maxLength,
    prefix,
    suffix,
  } = props;

  const getLabel = () => {
    if (label !== undefined && value !== undefined && value !== "") {
      return label;
    }

    return "";
  }

  const getPlaceholder = () => {
    if (value === undefined || value === "") {
      return placeholder;
    }

    return "";
  }

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (onChange) {
      if (allowedCharacters) {
        onChange(e.target.value.replace(new RegExp(`[^${allowedCharacters}]`, 'g'), ''))
      } else {
        onChange(e.target.value);
      }
    }
  }

  const handleBlur = (e: React.FocusEvent<HTMLInputElement>) => {
    if (onBlur) {
      if (allowedCharacters) {
        onBlur(e.target.value.replace(new RegExp(`[^${allowedCharacters}]`, 'g'), ''))
      } else {
        onBlur(e.target.value);
      }
    }
  }

  const handleDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (onEnter && e.key === "Enter") {
      onEnter(value || "");
    }
  }

  const getTextColor = () => {
    if (disabled) { return "text-gray-500" };
    if (textColor) { return textColor }
    return "text-black";
  }

  const getBackgroundColor = () => {
    if (disabled) { return "bg-white" };
    if (backgroundColor) { return backgroundColor };
    return "bg-white";
  }

  return (
    <div className={`flex flex-col rounded-lg border-gray-300 border p-2 justify-center ${getTextColor()} ${getBackgroundColor()} ${className}`}>
      {label && (
        <label className={`ml-2 text-xs font-semibold ${getTextColor()} ${getBackgroundColor()}`} htmlFor={label}>
          {getLabel()}
        </label>
      )}
      <div className="flex flex-row px-2 gap-1">
        {value && (<> {prefix}</>)}
        <input
          disabled={disabled}
          className={`w-full rounded-md border-none  focus:outline-none focus:ring-0  ${getTextColor()} ${getBackgroundColor()}`}
          type={type || "text"}
          name={getLabel()}
          onChange={handleChange}
          onBlur={handleBlur}
          onKeyDown={handleDown}
          value={value}
          placeholder={getPlaceholder()}
          maxLength={maxLength}
        />
        {value && (<> {suffix}</>)}
      </div>
    </div>
  );
}
