import { MdOpenInNew } from "react-icons/md";
import { Comparable } from "../../../generated/data-contracts";

export default function ComparableTileMobile(props: {
  comparable: Comparable;
  onClick?: (comparable: Comparable) => void;
  onClickImage?: (comparable: Comparable) => void;
}) {
  const { comparable, onClick, onClickImage } = props;

  return (
    <div
      className="flex flex-row cursor-pointer gap-2 text-white"
      onClick={() => onClick ? onClick(comparable) : null}
    >
      <div className="flex shrink-0 bg-thriftlyGreyDark rounded-thriftlyButton overflow-hidden items-center border border-thriftlyGrey">
        <img className="max-h-[68px] w-[68px] object-contain" src={comparable.image} alt="comparable" onClick={(e) => { e.stopPropagation(); onClickImage ? onClickImage(comparable) : null }} />
      </div>
      <div className="flex flex-col w-full">
        <div className="flex gap-4 justify-between">
          <div className="text-lg line-clamp-2">{comparable.title}</div>
          <div className="flex flex-row gap-4 items-center">
            <div className="text-sm lowercase line-clamp-1">{comparable.source}</div>
            <div className="flex flex-row shrink-0 items-center justify-center h-[25px] w-[25px] cursor-pointer"
              onClick={(e) => {
                e.stopPropagation();
                window.open(comparable.link, "_blank");
              }}>
              <div className="flex items-center justify-center bg-opacity-10 bg-thriftlyOffWhite rounded h-[25px] w-[25px]">
                <MdOpenInNew fill="#ffffff" className="h-[15px] w-[15px]" />
              </div>
            </div>
          </div>
        </div>
        <div className="opacity-65 text-sm line-clamp-1">{comparable.price ? comparable?.currency + comparable.price.toFixed(2) : <>&nbsp;</>}</div>
      </div>
    </div>
  );
}
