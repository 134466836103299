import { createContext } from "react";
import { Users } from "../../generated/Users";
import { Processes } from "../../generated/Processes";
import { Organizations } from "../../generated/Organizations";
import { Containers } from "../../generated/Containers";
import { Locations } from "../../generated/Locations";

type OrgContextType = {
  login?: Users;
  organizations?: Organizations;
  processes?: Processes;
  containers?: Containers;
  locations?: Locations;
};

export const ApiContext = createContext<OrgContextType>({
  login: undefined,
  organizations: undefined,
  processes: undefined,
  containers: undefined,
  locations: undefined,
});
