import { useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { AxiosError } from "axios";
import Header from "../../components/header/Header";
import Itembadge from "../../components/header/ItemBadge";
import MenuPopover from "../../components/header/MenuPopover";
import { ApiContext } from "../../components/context/ApiContext";
import { ErrorResponse, ProcessResponse, UserRole } from "../../generated/data-contracts";
import Loader from "../../components/common/Loader";
import { ROUTES } from "../../constants";
import { Button } from "../../components/common/Button";
import { OrgContext } from "../../components/context/OrgContext";
import { setPriceAppConfig } from "../../utils/localStorage";
import { isMobile } from "react-device-detect";

export default function ListerPage() {
    const { orgId, role, email } = useContext(OrgContext);
    const navigate = useNavigate();
    const { processes: processApi, organizations: organizationsApi } = useContext(ApiContext);
    const [itemsCount, setItemsCount] = useState<number>();

    const [isLoading, setIsLoading] = useState(true);
    const [error, setError] = useState<string>();
    const [nothingToReview, setNothingToReview] = useState(false);

    const getNext = () => {
        setIsLoading(true);
        setNothingToReview(false);

        processApi?.
            itemsNextList()
            .then(({ data }: { data: ProcessResponse }) => {
                if (data.process) {
                    navigate(`${ROUTES.PROCESS_PAGE}/${data.process.id}`);
                } else {
                    setNothingToReview(true);
                }
            })
            .catch((error: AxiosError<ErrorResponse>) => {
                setError(error.response?.data.error || "An error occurred. Please try again.");
            })
            .finally(() => {
                setIsLoading(false);
            });
    }

    const getItemsCount = () => {
        processApi
            ?.itemsRemainingList()
            .then((resp) => {
                setItemsCount(resp.data.itemsRemaining);
            })
            .catch((err: AxiosError) => {
                console.error((err.response?.data as ErrorResponse)?.error || "An error occurred. Please try again.");
            })
    }

    useEffect(() => {
        organizationsApi?.priceAppConfigDetail(orgId).then((response) => {
            if (response.data.priceAppConfig) setPriceAppConfig(response.data.priceAppConfig);
        });
        getItemsCount();
        getNext();
    }, [1]);

    return (
        <div className="h-screen">
            <Header
                darkMode
                left={<MenuPopover role={role as UserRole} darkMode />}
                right={<div className="flex flex-row gap-4 justify-end items-center pr-4">
                    {!isMobile && (<div className="text-thriftlyOffWhite">{email}</div>)}
                    <Itembadge role={role as UserRole} itemsCount={itemsCount} />
                </div>}
            />
            <div className="flex h-1/2 w-full justify-center items-center p-4">
                {isLoading && (<Loader />)}
                {error && <p className="mt-4 text-red-500">{error}</p>}
                {nothingToReview && (
                    <div className="flex flex-col gap-4">
                        <p className="mt-4 text-white">There is nothing to review. Please try again later.</p>
                        <Button className="w-full" onClick={() => { getNext() }}>Refresh</Button>
                    </div>
                )}
            </div>
        </div>
    );
}