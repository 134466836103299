import { useContext, useEffect, useState } from "react";
import { toast } from "react-toastify";
import { AxiosError } from "axios";
import { MdOpenInNew } from "react-icons/md";
import { StoreIcon } from "@shopify/polaris-icons";
import { DataGrid, gridClasses } from '@mui/x-data-grid';
import { ThemeProvider, createTheme } from '@mui/material/styles';
import dayjs from "dayjs";
import { ApiContext } from "../../components/context/ApiContext";
import { ErrorResponse, LocationStyles, Process, ProcessesResponse, UserRole } from "../../generated/data-contracts";
import Header from "../../components/header/Header";
import MenuPopover from "../../components/header/MenuPopover";
import ImageModal from "../../components/ImageModal";
import { OrgContext } from "../../components/context/OrgContext";
import Loader from "../../components/common/Loader";
import { getOrgStyles } from "../../utils/localStorage";
import { isMobile } from "react-device-detect";

export default function InventoryPage() {
    const { userId, role, email } = useContext(OrgContext);
    const { processes: processApi } = useContext(ApiContext);
    const [orgStyles] = useState<LocationStyles>(getOrgStyles());

    const [isLoading, setIsLoading] = useState(true);
    const [processes, setProcesses] = useState<Process[]>([]);
    const [showImageModal, setShowImageModal] = useState(false);
    const [selectedImage, setSelectedImage] = useState<string | undefined>();

    // fetch processes
    useEffect(() => {
        processApi
            ?.processesList({ date: dayjs().format("YYYY-MM-DD"), userId: userId }, {})
            .then(({ data }: { data: ProcessesResponse }) => {
                if (data.processes) setProcesses(data.processes);
            })
            .catch((err: AxiosError) => {
                console.error((err.response?.data as ErrorResponse)?.error);
                toast("An error occurred. Please try again.");
            })
            .finally(() => { setIsLoading(false) });
    }, [1]);

    const getSrcWithSize = (src: string) => {
        return `${src}?width=300&height=300`;
    }

    const getBody = () => {
        return <>
            <div className="min-h-[400px] px-4">
                <ThemeProvider theme={createTheme({ palette: { mode: 'dark' } })}>
                    <DataGrid
                        sx={{
                            '&.MuiDataGrid-root--densityCompact .MuiDataGrid-cell': { py: '8px' },
                            '&.MuiDataGrid-root--densityStandard .MuiDataGrid-cell': { py: '15px' },
                            '&.MuiDataGrid-root--densityComfortable .MuiDataGrid-cell': { py: '22px' },
                            [`& .${gridClasses.cell}:focus, & .${gridClasses.cell}:focus-within`]: {
                                outline: 'none',
                            },
                            [`& .${gridClasses.columnHeader}:focus, & .${gridClasses.columnHeader}:focus-within`]:
                            {
                                outline: 'none',
                            },
                        }}
                        rows={processes || []}
                        columns={[
                            {
                                field: 'createdAt',
                                headerName: 'Date',
                                minWidth: 150,
                                valueGetter: (value: string) => { return dayjs(value).format('M/D/YYYY HH:mmA') },
                                resizable: false
                            },
                            {
                                field: 'images',
                                headerName: 'Images',
                                sortable: false,
                                renderCell: (params) => {
                                    return (
                                        <div className="flex flex-row">
                                            {params.row.images?.map((image: string, index: number) => (
                                                <img key={index} src={getSrcWithSize(image)} alt="image" className="w-[32px] h-[32px] mr-[5px] cursor-pointer" onClick={() => {
                                                    setSelectedImage(image);
                                                    setShowImageModal(true);
                                                }} />
                                            ))}
                                        </div>
                                    )
                                },
                                resizable: false
                            },
                            {
                                field: 'title',
                                headerName: 'Title',
                                flex: 1,
                                resizable: false
                            },
                            {
                                field: 'price',
                                headerName: 'Price',
                                resizable: false
                            },
                            {
                                field: 'condition',
                                headerName: 'Condition',
                                cellClassName: 'capitalize',
                                resizable: false
                            },
                            {
                                field: 'username',
                                headerName: 'User',
                                minWidth: 150,
                                resizable: false
                            },
                            {
                                field: 'action',
                                headerName: 'Actions',
                                sortable: false,
                                renderCell: (params) => {
                                    return (
                                        <div className="flex flex-row gap-2 items-center">
                                            <MdOpenInNew className="cursor-pointer w-[24px] h-[24px]" onClick={() => { window.open(`/process/${params.row.id}`, "_blank"); }} />
                                            {params.row.inventory?.link && (
                                                <StoreIcon fill="#FFFFFF" className="cursor-pointer w-[32px] h-[32px]" onClick={() => { window.open(params.row.inventory?.link, "_blank"); }} />
                                            )}
                                        </div>
                                    )
                                },
                                resizable: false
                            },
                        ]}
                        getRowHeight={() => 'auto'}
                        initialState={{ pagination: { paginationModel: { pageSize: 10 } } }}
                        pageSizeOptions={[10]}
                        disableRowSelectionOnClick
                        disableColumnMenu
                    />
                </ThemeProvider>
            </div>
            {showImageModal && (<ImageModal image={selectedImage} onHide={() => setShowImageModal(false)} />)}
        </>;
    }

    return (
        <div className="flex h-dvh flex-col">
            <Header
                darkMode
                left={<MenuPopover role={role as UserRole} darkMode />}
                right={!isMobile && (<div className="flex justify-end pr-4 text-thriftlyOffWhite">{email}</div>)}
            />
            {isLoading ? (<div className="w-full h-dvh flex justify-center items-center">
                <Loader color={orgStyles.primaryColor} />
            </div>) : getBody()}
        </div>
    );
}