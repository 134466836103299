import { useEffect, useRef } from "react";
import { toast } from "react-toastify";
import { ScannerResponse } from "../types";
import { StationType } from "../generated/data-contracts";

type Props = {
    barcodeData?: string;
    onUserScan?: (scannerId: number, userId: string) => void;
    onPrinterScan?: (scannerId: number, printerId: string, stationType: StationType) => void;
    onContainerLabel?: (scannerId: number | undefined, containerId: string) => void;
    onPriceLabel?: (scannerId: number | undefined, processId: string) => void;
    onFixedPriceScan?: (
        scannerId: number | undefined,
        price?: string,
        condition?: string,
        category?: string,
        gender?: string,
        stationType?: StationType) => void;
};

const regexpUserScan = /^(?:\\000026)?user_([0-9a-f]{8}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{12})$/;
const regexpPrinterScan = /^(?:\\000026)?printer_(.*)_(hard|soft)$/;
const regexpContainerLabel = /^(?:\\000026)?con_([0-9a-f]{8}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{12})$/;
const regexpPriceLabel = /^(?:\\000026)?https?:\/\/.*\/shop\/([0-9a-f]{8}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{12})$/;
const regexpFixedPriceScanLegacy = /^(?:\\000026)?price_(\$?\d+\.\d{2})$/;
const regexpFixedPriceScan = /^(?:\\000026)?(search\?.*)$/;

export default function BarcodeListener({ barcodeData, onUserScan, onPrinterScan, onContainerLabel, onPriceLabel, onFixedPriceScan }: Props) {
    const mountedRef = useRef(false);
    const keyPresses = useRef("");
    const intervalRef = useRef<number>();

    const processKeypresses = (keyPresses: string, scannerId?: number | undefined) => {
        // price label
        const priceLabelMatches = keyPresses.match(regexpPriceLabel);
        if (priceLabelMatches && priceLabelMatches.length > 1) {
            onPriceLabel?.(scannerId, priceLabelMatches[1]);
            return true;
        }

        // container label
        const containerLabelMatches = keyPresses.match(regexpContainerLabel);
        if (containerLabelMatches && containerLabelMatches.length > 1) {
            onContainerLabel?.(scannerId, containerLabelMatches[1]);
            return true;
        }

        // fixed price scan (legacy)
        const fixedPriceScanMatchesLegacy = keyPresses.match(regexpFixedPriceScanLegacy);
        if (fixedPriceScanMatchesLegacy && fixedPriceScanMatchesLegacy.length > 1) {
            onFixedPriceScan?.(scannerId, fixedPriceScanMatchesLegacy[1]);
            return true;
        }

        // user scan
        if (scannerId) {
            const userScanMatches = keyPresses.match(regexpUserScan);
            if (userScanMatches && userScanMatches.length > 1) {
                onUserScan?.(scannerId, userScanMatches[1]);
                return true;
            }

            // printer scan
            const printerScanMatches = keyPresses.match(regexpPrinterScan);
            if (printerScanMatches && printerScanMatches.length > 2) {
                onPrinterScan?.(scannerId, printerScanMatches[1], printerScanMatches[2] === "hard" ? StationType.StationTypeHardline : StationType.StationTypeSoftline);
                return true;
            }
        }
    }

    const processBarcode = (barcode: string, scannerId?: number | undefined) => {
        // fixed price scan (dynamic parameters require processing on interval)
        const fixedPriceScanMatches = barcode.match(regexpFixedPriceScan);
        if (fixedPriceScanMatches && fixedPriceScanMatches.length > 1) {
            try {
                const params = new URL(`http://${fixedPriceScanMatches[1]}`).searchParams;
                onFixedPriceScan?.(
                    scannerId,
                    params.get("price") || undefined,
                    params.get("condition") || undefined,
                    params.get("category") || undefined,
                    params.get("gender") || undefined,
                    params.get("stationType") as StationType || undefined
                );
            } catch (e) {
                if (e instanceof Error) {
                    toast(`Error parsing scanner response JSON: ${e.message}`);
                }
                toast("Unknown error parsing scanner response JSON");
            }

            return true;
        }

        return false;
    }

    const startInterval = () => {
        intervalRef.current = setInterval(() => {
            processBarcode(keyPresses.current);
            keyPresses.current = "";
        }, 50);
    }

    const handleKeyDown = (e: KeyboardEvent) => {
        if (e.key.length === 1) {
            keyPresses.current += e.key;
            clearInterval(intervalRef.current);
            startInterval();
            if (processKeypresses(keyPresses.current)) {
                keyPresses.current = "";
            }
        }
    }

    useEffect(() => {
        if (mountedRef.current) { return };
        mountedRef.current = true;

        document.addEventListener('keydown', handleKeyDown);

        // timeout for keypresses
        startInterval();
    }, []);

    useEffect(() => {
        return () => {
            if (!mountedRef.current) { return };
            mountedRef.current = false;
            clearInterval(intervalRef.current);
            document.removeEventListener('keydown', handleKeyDown);
        };
    }, []);

    useEffect(() => {
        if (barcodeData) {
            try {
                const msg: ScannerResponse = JSON.parse(barcodeData!);
                if (msg.messageType !== "barcode" || msg.barcode === undefined) { return; }
                if (!processKeypresses(msg.barcode, msg.scannerId)) {
                    processBarcode(msg.barcode, msg.scannerId);
                }
            } catch (e) {
                if (e instanceof Error) {
                    toast(`Error parsing scanner response JSON: ${e.message}`);
                }
                toast("Unknown error parsing scanner response JSON");
            }
        }
    }, [barcodeData]);

    return (<></>);
}