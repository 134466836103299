import { ORG_STYLES_KEY, PRICE_APP_CONFIG_KEY, USER_SESSIONS_KEY } from "../constants";
import { LocationStyles, PriceAppConfig, StationType } from "../generated/data-contracts";
import { UserSession } from "../types";

export function getOrgStyles(): LocationStyles {
    const orgStyles = localStorage.getItem(ORG_STYLES_KEY);
    if (orgStyles) {
        return JSON.parse(orgStyles);
    }

    return {
        primaryColor: "#35644F",
        secondaryColor: "#00B764",
        logo: "https://images.thriftly.com/thriftly-logo.svg",
        logoDarkMode: "https://images.thriftly.com/thriftly-logo-white.svg",
        hidePoweredByThriftly: false,
    };
}

export function setOrgStyles(orgStyles: LocationStyles) {
    localStorage.setItem(ORG_STYLES_KEY, JSON.stringify(orgStyles));
}

export function getPriceAppConfig(): PriceAppConfig {
    const orgStyles = localStorage.getItem(PRICE_APP_CONFIG_KEY);
    if (orgStyles) {
        return JSON.parse(orgStyles);
    }

    return {};
}

export function setPriceAppConfig(priceAppConfig: PriceAppConfig) {
    localStorage.setItem(PRICE_APP_CONFIG_KEY, JSON.stringify(priceAppConfig));
}

export function getStationType(): StationType {
    return localStorage.getItem("stationType") as StationType || StationType.StationTypeSoftline;
}

export function setStationType(stationType: StationType) {
    localStorage.setItem("stationType", stationType);
}

export function getCameraRotation() {
    return localStorage.getItem("cameraRotation") || "0";
}

export function setCameraRotation(cameraRotation: string) {
    localStorage.setItem("cameraRotation", cameraRotation);
}

export function getCameraFormat() {
    return localStorage.getItem("cameraFormat") || "square";
}

export function setCameraFormat(cameraFormat: string) {
    localStorage.setItem("cameraFormat", cameraFormat);
}

export function getCameraResolution() {
    return localStorage.getItem("cameraResolution") || "1080p";
}

export function setCameraResolution(cameraResolution: string) {
    localStorage.setItem("cameraResolution", cameraResolution);
}

export function getAiAssistantImageCount() {
    return localStorage.getItem("aiAssistantImageCount") || "3";
}

export function setAiAssistantImageCount(aiAssistantImageCount: string) {
    localStorage.setItem("aiAssistantImageCount", aiAssistantImageCount);
}

export function getUserSessions(): UserSession[] {
    const userSessions = localStorage.getItem(USER_SESSIONS_KEY);
    if (userSessions) {
        return JSON.parse(userSessions);
    }

    return [];
}

export function setUserSessions(userSessions: UserSession[]) {
    localStorage.setItem(USER_SESSIONS_KEY, JSON.stringify(userSessions));
}