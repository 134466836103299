import { forwardRef } from "react";
import cn from "../../utils/cn";

type Props = React.ButtonHTMLAttributes<HTMLButtonElement> & {
  className?: string;
  backgroundColor?: string;
  textColor?: string;
  disabled?: boolean;
};

export const Button = forwardRef<HTMLButtonElement, Props>(
  ({ className, backgroundColor, textColor, disabled, ...props }, ref) => {

    const getTextColor = () => {
      if (disabled) { return "text-gray-500" };
      if (textColor) { return textColor }
      return "text-black";
    }

    const getBackgroundColor = () => {
      if (disabled) { return "bg-thriftlyGreyLight" };
      if (backgroundColor) { return backgroundColor };
      return "bg-white";
    }

    return (
      <button
        className={cn(
          "rounded-thriftlyButton h-12 px-4 font-semibold",
          "disabled:bg-thriftlyOffWhite disabled:text-thriftlyGreyLight disabled:cursor-auto",
          "inline-flex items-center justify-center cursor-pointer border border-solid gap-2 whitespace-nowrap",
          `body-b2-medium shadow-sm`,
          "focus-visible:ring-1 focus-visible:ring-button-secondary-hover focus-visible:ring-opacity-20 focus-visible:bg-button-secondary outline-none",
          className,
          `${getTextColor()} ${getBackgroundColor()}`,
          `${disabled ? "cursor-not-allowed" : ""}`
        )}
        ref={ref}
        {...props}
        type={props.type ?? "button"}
      >
        {props.children}
      </button>
    );
  }
);
Button.displayName = "Button";
