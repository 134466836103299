import { useRef } from "react";
import { Comparable } from "../../../generated/data-contracts";
import ComparableTileDesktop from "./ComparableTileDesktop";


export default function ComparableSelectorDesktop(props: {
    comparables: Comparable[];
    selectedComparable: Comparable | undefined;
    onTakePicture: () => void;
    onSelectComparable: (comparable: Comparable) => void;
    onSelectImage: (image: string) => void;
}) {
    const { comparables, selectedComparable, onTakePicture, onSelectComparable, onSelectImage } = props;
    const scrollRef = useRef<HTMLDivElement>(null);

    return (
        <div className="h-full flex flex-col overscroll-y-auto overflow-y-auto">
            <div className="flex flex-col gap-2 px-4 py-2 text-thriftlyOffWhite text-xl">
                <div>Select a comp or <span className="underline cursor-pointer" onClick={() => onTakePicture()}>take a picture of the label</span>
                </div>
            </div>
            <div className="flex flex-row flex-wrap gap-4 p-4">
                {comparables?.map((comparable: Comparable, idx: number) => {
                    return (
                        <div key={idx}>
                            <ComparableTileDesktop
                                selected={selectedComparable === comparable}
                                comparable={comparable}
                                onClick={(c: Comparable, event) => {
                                    onSelectComparable(c);
                                    // scrollRef.current = event.currentTarget;
                                    setTimeout((function () {
                                        scrollRef.current?.scrollIntoView({ block: "center", behavior: "smooth" });
                                    }), 50);

                                }}
                                onClickImage={(c: Comparable) => { onSelectImage(c.image!) }}
                            />
                        </div>
                    );
                })}
            </div>
        </div>
    );
}
