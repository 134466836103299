/* eslint-disable */
/* tslint:disable */
/*
 * ---------------------------------------------------------------
 * ## THIS FILE WAS GENERATED VIA SWAGGER-TYPESCRIPT-API        ##
 * ##                                                           ##
 * ## AUTHOR: acacode                                           ##
 * ## SOURCE: https://github.com/acacode/swagger-typescript-api ##
 * ---------------------------------------------------------------
 */

import {
  CreateOrganizationRequest,
  DealsResponse,
  ErrorResponse,
  OrganizationResponse,
  OrganizationStylesResponse,
  OrganizationsResponse,
  PriceAppConfigResponse,
  ProcessesResponse,
  UpdateOrganizationRequest,
} from "./data-contracts";
import { ContentType, HttpClient, RequestParams } from "./http-client";

export class Organizations<SecurityDataType = unknown> extends HttpClient<SecurityDataType> {
  /**
   * @description get organizations
   *
   * @tags organizations
   * @name OrganizationsList
   * @summary Get organizations
   * @request GET:/organizations
   * @secure
   */
  organizationsList = (params: RequestParams = {}) =>
    this.request<OrganizationsResponse, ErrorResponse>({
      path: `/organizations`,
      method: "GET",
      secure: true,
      type: ContentType.Json,
      format: "json",
      ...params,
    });
  /**
   * @description get organization by ID
   *
   * @tags organizations
   * @name OrganizationsDetail
   * @summary Get an organization
   * @request GET:/organizations/{orgId}
   * @secure
   */
  organizationsDetail = (orgId: string, params: RequestParams = {}) =>
    this.request<OrganizationResponse, ErrorResponse>({
      path: `/organizations/${orgId}`,
      method: "GET",
      secure: true,
      type: ContentType.Json,
      format: "json",
      ...params,
    });
  /**
   * @description create organization
   *
   * @tags organizations
   * @name OrganizationsUpdate
   * @summary Create an organization
   * @request PUT:/organizations/{orgId}
   * @secure
   */
  organizationsUpdate = (orgId: string, requestBody: CreateOrganizationRequest, params: RequestParams = {}) =>
    this.request<OrganizationResponse, ErrorResponse>({
      path: `/organizations/${orgId}`,
      method: "PUT",
      body: requestBody,
      secure: true,
      type: ContentType.Json,
      format: "json",
      ...params,
    });
  /**
   * @description delete organization by ID
   *
   * @tags organizations
   * @name OrganizationsDelete
   * @summary Delete an organization
   * @request DELETE:/organizations/{orgId}
   * @secure
   */
  organizationsDelete = (orgId: string, params: RequestParams = {}) =>
    this.request<void, ErrorResponse>({
      path: `/organizations/${orgId}`,
      method: "DELETE",
      secure: true,
      type: ContentType.Json,
      ...params,
    });
  /**
   * @description update organization by ID
   *
   * @tags organizations
   * @name OrganizationsPartialUpdate
   * @summary Update an organization
   * @request PATCH:/organizations/{orgId}
   * @secure
   */
  organizationsPartialUpdate = (orgId: string, request: UpdateOrganizationRequest, params: RequestParams = {}) =>
    this.request<OrganizationResponse, ErrorResponse>({
      path: `/organizations/${orgId}`,
      method: "PATCH",
      body: request,
      secure: true,
      type: ContentType.Json,
      format: "json",
      ...params,
    });
  /**
   * @description get deals by org ID
   *
   * @tags deals
   * @name DealsDetail
   * @summary Get deals by org
   * @request GET:/organizations/{orgId}/deals
   * @secure
   */
  dealsDetail = (orgId: string, params: RequestParams = {}) =>
    this.request<DealsResponse, ErrorResponse>({
      path: `/organizations/${orgId}/deals`,
      method: "GET",
      secure: true,
      type: ContentType.Json,
      format: "json",
      ...params,
    });
  /**
   * @description upload an organization logo by ID
   *
   * @tags organizations
   * @name LogoCreate
   * @summary Upload an organization logo
   * @request POST:/organizations/{orgId}/logo
   * @secure
   */
  logoCreate = (
    orgId: string,
    data: {
      /** Donation Image */
      image?: File;
    },
    params: RequestParams = {},
  ) =>
    this.request<OrganizationResponse, ErrorResponse>({
      path: `/organizations/${orgId}/logo`,
      method: "POST",
      body: data,
      secure: true,
      type: ContentType.FormData,
      format: "json",
      ...params,
    });
  /**
   * @description get price app config by organization ID
   *
   * @tags organizations
   * @name PriceAppConfigDetail
   * @summary Get price app config
   * @request GET:/organizations/{orgId}/priceAppConfig
   * @secure
   */
  priceAppConfigDetail = (orgId: string, params: RequestParams = {}) =>
    this.request<PriceAppConfigResponse, ErrorResponse>({
      path: `/organizations/${orgId}/priceAppConfig`,
      method: "GET",
      secure: true,
      type: ContentType.Json,
      format: "json",
      ...params,
    });
  /**
   * @description get pull list
   *
   * @tags processes
   * @name PullListDetail
   * @summary Get Pull List
   * @request GET:/organizations/{orgId}/pullList
   * @secure
   */
  pullListDetail = (
    orgId: string,
    query?: {
      /** date */
      date?: string;
    },
    params: RequestParams = {},
  ) =>
    this.request<ProcessesResponse, ErrorResponse>({
      path: `/organizations/${orgId}/pullList`,
      method: "GET",
      query: query,
      secure: true,
      type: ContentType.Json,
      format: "json",
      ...params,
    });
  /**
   * @description get organization styles by ID
   *
   * @tags organizations
   * @name StylesDetail
   * @summary Get organization styles
   * @request GET:/organizations/{orgId}/styles
   */
  stylesDetail = (orgId: string, params: RequestParams = {}) =>
    this.request<OrganizationStylesResponse, ErrorResponse>({
      path: `/organizations/${orgId}/styles`,
      method: "GET",
      type: ContentType.Json,
      format: "json",
      ...params,
    });
}
