import { CameraIcon } from "@shopify/polaris-icons";
import { MdClose } from "react-icons/md";
import Loader from "../../../components/common/Loader";
import { isMobile } from "react-device-detect";

export default function ImageCarousel(props: {
    images: string[],
    onSelectImageClick: (image: string) => void,
    onDeleteImageClick: (image: string) => void,
    onAddPhotoClick: () => void
    isUploadingImage: boolean;
}) {
    const { images, onSelectImageClick, onDeleteImageClick, onAddPhotoClick, isUploadingImage } = props;

    const getSizeClasses = () => {
        if (isMobile) {
            return "h-[110px] w-[110px]";
        }

        return "h-[140px] w-[140px]";
    }

    const getSrcWithSize = (src: string) => {
        return `${src}?width=300&height=300`;
    }

    return <div className="flex flex-row gap-2">
        <div className="flex flex-row gap-2 overflow-x-auto">
            {images.map((image, idx) => (
                <div key={idx} className={`relative ${getSizeClasses()} flex shrink-0 bg-thriftlyGreyDark rounded-thriftlyCamera overflow-hidden`}>
                    <img
                        className={`absolute ${getSizeClasses()} object-contain cursor-pointer`}
                        src={getSrcWithSize(image)}
                        alt="comparable"
                        onClick={(e) => { e.stopPropagation(); onSelectImageClick(image) }} />
                    <div className="h-[40px] w-[40px] relative flex ml-auto bg-white bg-opacity-40 cursor-pointer rounded-bl-thriftlyCamera" onClick={() => onDeleteImageClick(image)}>
                        <MdClose className="h-[40px] w-[40px]" fill="#000000" />
                    </div>
                </div>
            ))}
        </div>
        <div
            className={`${getSizeClasses()} flex shrink-0 flex-col rounded-thriftlyCamera overflow-hidden items-center justify-center text-thriftlyGreyLight border border-thriftlyGreyDark cursor-pointer`}
            onClick={() => onAddPhotoClick()}>
            {isUploadingImage ? (<Loader />) : (<>
                <CameraIcon fill="#989FA4" className="h-[24px] w-[24px]" />
                Add photo
            </>)}
        </div>
    </div>
}