import { useState } from "react";
import { MdClose, MdWarning } from "react-icons/md";

export default function WarningToast(props: {
    text: string,
    closeable?: boolean
}) {
    const { text, closeable } = props;
    const [isHidden, setIsHidden] = useState(false);

    if (isHidden) return null;

    return <div
        className={`flex flex-row shrink gap-4 mt-auto bg-thriftlyOffWhite rounded-thriftlyButton p-4 text-thriftlyBlack text-xl items-center justify-between ${closeable ? "cursor-pointer" : ""}`}
        onClick={() => { if (closeable) setIsHidden(true) }}>
        <div className="flex flex-row gap-4 items-center">
            <MdWarning fill="#000000" className="w-[24px] h-[24px]" />{text}
        </div>
        {closeable && (<MdClose />)}
    </div>;
}