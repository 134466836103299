import { useContext, useEffect, useState } from "react";
import CustomDropdownBlack from "../../components/common/CustomDropdownBlack";
import Header from "../../components/header/Header";
import MenuPopover from "../../components/header/MenuPopover";
import {
    getStationType as getStationTypeLocalStorage,
    setStationType as setStationTypeLocalStorage,
    getCameraRotation as getCameraRotationLocalStorage,
    setCameraRotation as setCameraRotationLocalStorage,
    getCameraFormat as getCameraFormatLocalStorage,
    setCameraFormat as setCameraFormatLocalStorage,
    getCameraResolution as getCameraResolutionLocalStorage,
    setCameraResolution as setCameraResolutionLocalStorage,
    getAiAssistantImageCount as getAiAssistantImageCountLocalStorage,
    setAiAssistantImageCount as setAiAssistantImageCountLocalStorage,
} from "../../utils/localStorage";
import { StationType, UserRole } from "../../generated/data-contracts";
import { OrgContext } from "../../components/context/OrgContext";
import { isMobile } from "react-device-detect";
import { capitalize } from "../../utils/text";

export default function SettingsPage() {
    const { role, email } = useContext(OrgContext);
    const [stationType, setStationType] = useState(getStationTypeLocalStorage());
    const [cameraRotation, setCameraRotation] = useState(getCameraRotationLocalStorage());
    const [cameraFormat, setCameraFormat] = useState(getCameraFormatLocalStorage());
    const [cameraResolution, setCameraResolution] = useState(getCameraResolutionLocalStorage());
    const [aiAssistantImageCount, setAiAssistantImageCount] = useState(getAiAssistantImageCountLocalStorage);

    useEffect(() => {
        setStationTypeLocalStorage(stationType);
        setCameraRotationLocalStorage(cameraRotation);
        setCameraFormatLocalStorage(cameraFormat);
        setCameraResolutionLocalStorage(cameraResolution);
        setAiAssistantImageCountLocalStorage(aiAssistantImageCount);
    }, [stationType, cameraRotation, cameraFormat, cameraResolution, aiAssistantImageCount]);

    return (
        <div className="h-dvh overscroll-none overflow-y-clip">
            <Header
                darkMode
                left={<MenuPopover role={role as UserRole} darkMode />}
                right={!isMobile && (<div className="flex justify-end pr-4 text-thriftlyOffWhite">{email}</div>)}
            />
            <div className="flex flex-col items-center border-t-2 border-thriftlyGreyDark pt-2">
                <div className="flex flex-col w-96 gap-2">
                    <div className="flex flex-col min-h-24 gap-8">
                        <div className="text-2xl text-white text-center">Settings</div>
                        <div className="grid grid-cols-2 items-center">
                            <div className="text-white">Station type</div>
                            <CustomDropdownBlack
                                placeholder="Select station type"
                                options={Object.values(StationType).map((option) => {
                                    return { label: capitalize(option.replace('_', ' ')), value: option }
                                })}
                                value={stationType}
                                capitalize
                                onChange={(value) => { setStationType(value as StationType) }}
                            />
                            <div className="text-white">Camera rotation</div>
                            <CustomDropdownBlack
                                placeholder="Select camera rotation"
                                options={[
                                    { label: "0°", value: "0" },
                                    // { label: "90°", value: "90" },
                                    { label: "180°", value: "180" },
                                    // { label: "270°", value: "270" },
                                ]}
                                value={cameraRotation}
                                onChange={setCameraRotation}
                            />
                            <div className="text-white">Camera format</div>
                            <CustomDropdownBlack
                                placeholder="Select camera format"
                                options={[
                                    { label: "Square", value: "square" },
                                    { label: "Landscape", value: "landscape" },
                                    { label: "Portrait", value: "portrait" },
                                ]}
                                value={cameraFormat}
                                onChange={setCameraFormat}
                            />
                            <div className="text-white">Camera resolution</div>
                            <CustomDropdownBlack
                                placeholder="Select camera resolution"
                                options={[
                                    { label: "1080p", value: "1080p" },
                                    { label: "720p", value: "720p" },
                                    { label: "480p", value: "480p" },
                                ]}
                                value={cameraResolution}
                                onChange={setCameraResolution}
                            />
                            <div className="text-white">AI Assistant Image Count</div>
                            <CustomDropdownBlack
                                placeholder="Select image count"
                                options={[
                                    { label: "2", value: "2" },
                                    { label: "3", value: "3" },
                                    { label: "4", value: "4" },
                                    { label: "5", value: "5" },
                                    { label: "6", value: "6" },
                                    { label: "7", value: "7" },
                                    { label: "8", value: "8" },
                                    { label: "9", value: "9" },
                                    { label: "10", value: "10" },
                                ]}
                                value={aiAssistantImageCount}
                                onChange={setAiAssistantImageCount}
                            />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}
