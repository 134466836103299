import { useContext, useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import { AxiosError } from "axios";
import { ContainerResponse, ErrorResponse, GeneratedProcessResponse, PricingProcessor, Process, ProcessResponse, StationType, UserRole } from "../../generated/data-contracts";
import { ApiContext } from "../../components/context/ApiContext";
import { OrgContext } from "../../components/context/OrgContext";
import Header from "../../components/header/Header";
import Itembadge from "../../components/header/ItemBadge";
import MenuPopover from "../../components/header/MenuPopover";
import ImageModal from "../../components/ImageModal";
import { Button } from "../../components/common/Button";
import { getAiAssistantImageCount, getPriceAppConfig, getStationType } from "../../utils/localStorage";
import CameraModal from "./Components/CameraModal";
import BarcodeListener from "../../components/BarcodeListener";
import { ROUTES } from "../../constants";
import { toast } from "react-toastify";
import useWebSocket from "react-use-websocket";
import ImageCarousel from "./Components/ImageCarousel";
import InputFields from "./Components/InputFields";
import AiAssistantWidget from "./Components/AiAssistantWidget";
import ScanInstructions from "./Components/ScanInstructions";
import { isMobile } from 'react-device-detect';
import { isSet } from "../../utils/validation";
import ComparableSelectorMobile from "./Components/ComparableSelectorMobile";
import ComparableSelectorDesktop from "./Components/ComparableSelectorDesktop";
import { canPrint } from "../../utils/printers";
import WarningToast from "./Components/WarningToast";

export default function ProcessPage({ process: processResponse }: { process: Process | undefined }) {
    const { VITE_API_BASE_URL } = import.meta.env;
    const navigate = useNavigate();
    const { role, email } = useContext(OrgContext);
    const { processes: processApi, containers: containersApi } = useContext(ApiContext);
    const { lastMessage } = useWebSocket('ws://localhost:8089');

    const [process, setProcess] = useState<Process>({ ...processResponse });
    const [processDraft, setProcessDraft] = useState<Process>({ ...process });
    const [generatedProcess, setGeneratedProcess] = useState<Process>();
    const [timeSaved, setTimeSaved] = useState<number>(0);
    const [itemsCount, setItemsCount] = useState<number>();

    const modalStateRef = useRef<undefined | "editProcess" | "priceLabel" | "containerLabel" | "createContainerLabel">(function () {
        if (process.stationType === StationType.StationTypeSoftline) return "editProcess";
        if (!process.comparables || process.comparables?.length === 0) return "editProcess";
        return undefined;
    }());
    const [modalState, setModalState] = useState<undefined | "editProcess" | "priceLabel" | "containerLabel" | "createContainerLabel">(modalStateRef.current);
    const [isGenerating, setIsGenerating] = useState(false);

    const [selectedImage, setSelectedImage] = useState<string>();
    const [showImageModal, setShowImageModal] = useState(false);
    const [showCameraModal, setShowCameraModal] = useState(false);
    const mobileCameraRef = useRef<HTMLInputElement>(null);
    const [isUploadingImage, setIsUploadingImage] = useState(false);

    const successBeep = new Audio("/beep-success.mp3");
    const errorBeep = new Audio("/beep-error.mp3");
    const printerLoaded = useRef(false);
    const selectedPrinter = useRef<any>();
    const initializedRef = useRef(false);

    useEffect(() => {
        modalStateRef.current = modalState;
    }, [modalState]);

    // get default label printer
    useEffect(() => {
        if (!canPrint() || printerLoaded.current) return;
        printerLoaded.current = true;
        (window as any).BrowserPrint.getDefaultDevice("printer", function (printer: any) {
            selectedPrinter.current = printer;
        }, function (error: string) {
            toast("Error getting default printer: " + error);
        })
    }, [1]);

    const getItemsCount = () => {
        role === UserRole.RolePricer ? processApi
            ?.itemsTodayList()
            .then((resp) => {
                setItemsCount(resp.data.itemsToday);
            })
            .catch((err: AxiosError) => {
                toast((err.response?.data as ErrorResponse)?.error || "An error occurred. Please try again.");
            }) : processApi
                ?.itemsRemainingList()
                .then((resp) => {
                    setItemsCount(resp.data.itemsRemaining);
                })
                .catch((err: AxiosError) => {
                    toast((err.response?.data as ErrorResponse)?.error || "An error occurred. Please try again.");
                });
    };

    useEffect(() => {
        if (initializedRef.current) return;
        initializedRef.current = true;

        getItemsCount();

        // Show no comparables toast on mobile
        if (process.stationType === StationType.StationTypeHardline &&
            (!process.comparables || process.comparables?.length === 0) &&
            isMobile) {
            toast("No comparables found");
        }
    }, [1]);

    const saveProcess = async () => {
        if (JSON.stringify(processDraft) === JSON.stringify(process)) return;
        await processApi?.processesUpdate(processDraft.id!, processDraft)
            .then(({ data }: { data: ProcessResponse }) => {
                setProcess(data.process!);
                setProcessDraft(data.process!);
                toast("Item updated.");
            })
            .catch((err: AxiosError) => {
                toast((err.response?.data as ErrorResponse)?.error || "An error occurred. Please try again.");
            });
    };

    const deleteImage = (image: string) => {
        const updateProcess = { ...processDraft, images: processDraft.images?.filter((i) => i !== image) }
        processApi?.processesUpdate(processDraft.id!, updateProcess)
            .then(({ data }: { data: ProcessResponse }) => {
                setProcessDraft(data.process!);
                toast("Image deleted.");
            })
            .catch((err: AxiosError) => {
                toast((err.response?.data as ErrorResponse)?.error || "An error occurred. Please try again.");
            });
    }

    const printPriceLabel = () => {
        if (!canPrint()) return;

        if (selectedPrinter === undefined) {
            toast("Error: No printer selected");
            return;
        }

        const url = `${VITE_API_BASE_URL}/processes/${process?.id}/label?print=true`;
        selectedPrinter.current.sendFile(url, undefined, (error: any) => {
            toast(`Error printing label: ${error}`);
        });
    }

    const printContainerLabel = (name: string) => {
        containersApi?.containersCreate({ name })
            .then(({ data }: { data: ContainerResponse }) => {
                toast("Container created.");
                setModalState("containerLabel");

                if (selectedPrinter === undefined) {
                    toast("Error: No printer selected");
                    return;
                }

                const url = `${VITE_API_BASE_URL}/containers/${data.container?.id}/label?print=true`;
                selectedPrinter.current.sendFile(url, undefined, (error: any) => {
                    toast(`Error printing label: ${error}`);
                });
            })
            .catch((err: AxiosError) => {
                toast((err.response?.data as ErrorResponse)?.error || "An error occurred. Please try again.");
            });
    }

    const selectImage = (image: string | undefined) => {
        setSelectedImage(image);
        setShowImageModal(true);
    }

    const onGenerate = () => {
        if (isGenerating) return;
        setIsGenerating(true);

        processApi?.generateCreate(processDraft.id!)
            .then(({ data }: { data: GeneratedProcessResponse }) => {
                setProcess(processDraft); // save a copy of draft changes
                setGeneratedProcess(data.process);
                setProcessDraft({ ...processDraft, ...data.process });
                setTimeSaved(data.timeSaved || 0);
                toast("Generated AI assisted data.");
            })
            .catch((err: AxiosError) => {
                toast((err.response?.data as ErrorResponse)?.error || "An error occurred. Please try again.");
            })
            .finally(() => { setIsGenerating(false) });
    }

    const getBody = () => {
        if (showCameraModal) {
            return (
                <div className="h-full flex flex-col items-center border-t-2 border-thriftlyGreyDark">
                    {((!processDraft?.comparables || processDraft?.comparables?.length === 0) && processDraft?.images?.length === 0) && (
                        <div className="flex flex-col gap-2 px-4 py-2 text-white">No comparables found. Take a picture of the label</div>
                    )}
                    <CameraModal
                        process={processDraft!}
                        onHide={
                            (updatedProcess) => {
                                setProcessDraft({ ...processDraft, images: updatedProcess.images });
                                setShowCameraModal(false);
                                if ((updatedProcess.images?.length || 0) >= Number(getAiAssistantImageCount())) {
                                    onGenerate();
                                }
                            }
                        }
                    />
                </div>
            )
        }

        if (modalState === undefined && process.stationType === StationType.StationTypeHardline && isMobile) {
            return <>
                <ComparableSelectorMobile
                    comparables={process?.comparables || []}
                    onSelectComparable={(c) => {
                        setProcessDraft({ ...processDraft, title: c.title, selectedComparable: c });
                        setModalState("editProcess");
                    }}
                    onSelectImage={selectImage}
                    onTakePicture={() => setShowCameraModal(true)}
                />
                {showImageModal && (<ImageModal image={selectedImage} onHide={() => setShowImageModal(false)} />)}
            </>
        }

        return (
            <>
                <div className={`h-full flex flex-row border-t-2 border-thriftlyGreyDark justify-center pb-[64px]`}>
                    {(modalState === undefined || modalState === "editProcess") && process.stationType === StationType.StationTypeHardline && !isMobile && process.comparables && process.comparables.length !== 0 && (
                        <div className={modalState === undefined ? "w-full" : "w-1/3"}>
                            <ComparableSelectorDesktop
                                comparables={process?.comparables || []}
                                selectedComparable={processDraft?.selectedComparable}
                                onSelectComparable={(c) => {
                                    setProcessDraft({ ...processDraft, title: c.title, selectedComparable: c });
                                    setModalState("editProcess");
                                }}
                                onSelectImage={selectImage}
                                onTakePicture={() => setShowCameraModal(true)}
                            />
                        </div>
                    )}
                    {modalState !== undefined && (
                        <div className={`flex flex-col gap-8 shrink-0 pt-4 px-4 ${isMobile ? "w-full" : "w-2/3"}`}>
                            <div className="h-full w-full flex flex-col gap-4">
                                <ImageCarousel
                                    images={processDraft?.images || []}
                                    onSelectImageClick={selectImage}
                                    onDeleteImageClick={deleteImage}
                                    onAddPhotoClick={() => {
                                        if (isMobile) {
                                            mobileCameraRef.current?.click();
                                            return;
                                        } else {
                                            setShowCameraModal(true);
                                        }
                                    }}
                                    isUploadingImage={isUploadingImage}
                                />
                                <input
                                    ref={mobileCameraRef}
                                    style={{ display: "none" }}
                                    type="file"
                                    accept="image/*"
                                    capture="environment"
                                    onChange={(e) => {
                                        if (e.target.files === null) {
                                            alert("No file selected");
                                            return;
                                        }

                                        // upload image
                                        setIsUploadingImage(true);
                                        processApi
                                            ?.imageCreate(process.id!, {
                                                image: new File([
                                                    e.target.files[0]
                                                ], "image", {
                                                    type: "image/png",
                                                }),
                                            })
                                            .then(({ data }: { data: ProcessResponse }) => {
                                                setProcessDraft({ ...processDraft, images: data.process?.images });
                                                toast("Image uploaded.");
                                                if ((data?.process?.images?.length || 0) >= Number(getAiAssistantImageCount())) {
                                                    onGenerate();
                                                }
                                            })
                                            .catch((err: AxiosError) => {
                                                toast((err.response?.data as ErrorResponse)?.error || "An error occurred. Please try again.");
                                            })
                                            .finally(() => { setIsUploadingImage(false) });
                                    }}
                                />
                                <div className="overscroll-y-auto overflow-y-auto">
                                    <InputFields
                                        process={process}
                                        processDraft={processDraft}
                                        onUpdateDraft={setProcessDraft}
                                        generatedProcess={generatedProcess}
                                        onUpdateGenerated={setGeneratedProcess}
                                    />
                                </div>
                                <div className="flex flex-col gap-4 pb-4 mt-auto">
                                    <AiAssistantWidget
                                        progress={Math.min(100, (100 / Number(getAiAssistantImageCount())) * (processDraft.images?.length || 0))}
                                        onGenerate={onGenerate}
                                        isWorking={isGenerating}
                                        timeSaved={timeSaved}
                                    />
                                    <div className="flex flex-row gap-4 justify-between">
                                        {modalState === "editProcess" && getPriceAppConfig().keepInStore && (
                                            <Button
                                                textColor="text-thriftlyOffWhite"
                                                backgroundColor="bg-thriftlyGreyDark"
                                                className={`w-full border-thriftlyGrey ${isMobile ? "" : "h-32 text-2xl"}`}
                                                onClick={async () => {
                                                    if (JSON.stringify(processDraft) !== JSON.stringify(process)) {
                                                        process.keepInStore = true;
                                                        await saveProcess();
                                                    }
                                                    navigate(ROUTES.PRICER_PAGE);
                                                }}
                                            >
                                                Keep in store
                                            </Button>
                                        )}
                                        {modalState === "editProcess" && process.stationType === StationType.StationTypeHardline && (process.comparables && process.comparables?.length !== 0) && (
                                            <Button
                                                textColor="text-thriftlyOffWhite"
                                                backgroundColor="bg-thriftlyGreyDark"
                                                className={`w-full border-thriftlyGrey ${isMobile ? "" : "h-32 text-2xl"}`}
                                                onClick={async () => {
                                                    setProcessDraft({ ...processDraft, selectedComparable: undefined });
                                                    setModalState(undefined);
                                                }}
                                            >
                                                Back
                                            </Button>
                                        )}
                                        {modalState === "editProcess" && (
                                            <Button
                                                textColor="text-thriftlyGreyDark"
                                                backgroundColor="bg-thriftlyOffWhite"
                                                className={`w-full border-thriftlyGrey ${isMobile ? "" : "h-32 text-2xl"}`}
                                                onClick={async () => {
                                                    if (!isSet(processDraft.title) || !isSet(processDraft?.price)) { return; }
                                                    if (JSON.stringify(processDraft) !== JSON.stringify(process)) {
                                                        await saveProcess();
                                                    }

                                                    if (canPrint()) {
                                                        printPriceLabel();
                                                        setModalState("priceLabel");
                                                    } else {
                                                        navigate(ROUTES.PRICER_PAGE);
                                                    }
                                                }}
                                                disabled={!isSet(processDraft.title) || !isSet(processDraft?.price) || processDraft?.price === "$"}
                                            >
                                                {canPrint() ? "Continue" : "Done"}
                                            </Button>
                                        )}
                                        {(modalState === "priceLabel" || modalState === "containerLabel") && JSON.stringify(processDraft) !== JSON.stringify(process) && (
                                            <Button
                                                textColor="text-thriftlyOffWhite"
                                                backgroundColor="bg-thriftlyGreyDark"
                                                className={`w-full h-32 border-thriftlyGrey text-2xl`}
                                                onClick={() => { setProcessDraft(process); }}
                                            >
                                                Cancel
                                            </Button>
                                        )}
                                        {modalState !== "editProcess" && (
                                            <Button
                                                textColor="text-thriftlyGreyDark"
                                                backgroundColor="bg-thriftlyOffWhite"
                                                className={`w-full h-32 border-thriftlyGrey text-2xl`}
                                                disabled={JSON.stringify(processDraft) === JSON.stringify(process)}
                                                onClick={async () => {
                                                    const printLabel = processDraft.price != process.price;
                                                    await saveProcess();
                                                    if (printLabel) {
                                                        printPriceLabel();
                                                        setModalState("priceLabel");
                                                    }
                                                }}
                                            >
                                                Save
                                            </Button>
                                        )}
                                    </div>
                                    {(modalState == "editProcess" &&
                                        process.processor === PricingProcessor.ProcessorSerpApi &&
                                        (!process.comparables || process.comparables?.length === 0) &&
                                        !isMobile) && (
                                            <WarningToast text="No comparables found" closeable />
                                        )}
                                    {(modalState !== "editProcess" && JSON.stringify(processDraft) !== JSON.stringify(process)) && (
                                        <WarningToast text="Unsaved changes" />
                                    )}
                                </div>
                            </div>

                        </div>
                    )}
                    {modalState !== undefined && modalState !== "editProcess" && (
                        <div className="w-1/3">
                            <ScanInstructions
                                modalState={modalState}
                                setModalState={setModalState}
                                printerName={selectedPrinter.current?.name}
                                unsavedChanges={JSON.stringify(processDraft) !== JSON.stringify(process)}
                                onPrintPriceLabel={printPriceLabel}
                                onPrintContainerLabel={printContainerLabel}
                            />
                        </div>
                    )}
                </div >
                {showImageModal && (<ImageModal image={selectedImage} onHide={() => setShowImageModal(false)} />)}
            </>);
    }

    return (
        <div className="h-dvh overscroll-none overflow-y-clip flex flex-col">
            <Header
                darkMode
                left={<div className="flex flex-row gap-4 items-center pl-4">
                    <MenuPopover role={role as UserRole} darkMode />
                    {!isMobile && (
                        <div className="flex items-center justify-end h-[20px] shrink-0">
                            <div
                                className="rounded text-xs uppercase py-1 px-2 bg-thriftlyGreyDark text-thriftlyOffWhite">
                                {process.stationType === StationType.StationTypeHardline ? "HARD GOODS" : "SOFT GOODS"}
                            </div>
                        </div>
                    )}
                </div>}
                right={<div className="flex flex-row gap-4 justify-end items-center pr-4">
                    {!isMobile && (
                        <div className="text-thriftlyOffWhite">{email}</div>
                    )}
                    <Itembadge role={role as UserRole} itemsCount={itemsCount} />
                </div>}
            />
            <BarcodeListener
                barcodeData={lastMessage?.data}
                onPriceLabel={(scannerId, barcode) => {
                    if (modalStateRef.current === "priceLabel" && JSON.stringify(processDraft) !== JSON.stringify(process)) {
                        toast("Save changes before proceeding.");
                        errorBeep.play();
                    } else if ((modalStateRef.current === "editProcess" || modalStateRef.current === "priceLabel") && barcode === processDraft?.id) {
                        successBeep.play();
                        setModalState("containerLabel");
                    } else if (modalStateRef.current === "priceLabel") {
                        errorBeep.play();
                        toast("Invalid barcode. Please try again.");
                    } else if (modalStateRef.current === "containerLabel") {
                        toast("Price label already scanned. Scan a container label.");
                        errorBeep.play();
                    }
                }}
                onContainerLabel={(scannerId, barcode) => {
                    if (modalStateRef.current === "containerLabel") {
                        containersApi?.placeCreate(barcode, { processIds: [process?.id!] })
                            .then(() => {
                                toast("Item added to inventory")
                                successBeep.play();
                                navigate(ROUTES.PRICER_PAGE);
                            })
                            .catch((err: AxiosError) => {
                                toast("An error occurred. Please try again.");
                                errorBeep.play();
                            });
                        successBeep.play();
                    } else if (modalStateRef.current === "priceLabel") {
                        toast("Price label not scanned. Scan a price label first.");
                        errorBeep.play();
                    }
                }}
            />
            {getBody()}
        </div>
    );
}