import { ReactNode, useState } from "react";
import { LocationStyles } from "../../generated/data-contracts";
import { getOrgStyles } from "../../utils/localStorage";

type HeaderProps = {
    darkMode?: boolean;
    left?: ReactNode;
    right?: ReactNode;
    bottom?: ReactNode;
}

export default function Header({ darkMode, left, right, bottom }: HeaderProps) {
    const [orgStyles] = useState<LocationStyles>(getOrgStyles());

    const getLogo = () => {
        if (darkMode) {
            return orgStyles.logoDarkMode || orgStyles.logo;
        }

        return orgStyles.logo;
    }

    return (
        <div className="items-center px-2 py-4">
            <div className="grid grid-cols-3 items-center">
                <div>{left}</div>
                <div className="flex justify-center h-[32px]">
                    <img className="object-contain" src={getLogo()} alt="thriftly" />
                </div>
                <div>{right}</div>
            </div>
            <div>{bottom}</div>
        </div>
    );
}
