import { useState, useEffect } from "react";

export default function useCameraPermission() {
  const [permission, setPermission] = useState("unknown");

  useEffect(() => {
    const getPermission = async () => {
      try {
        await navigator.mediaDevices.getUserMedia({ video: true });
        setPermission("granted");
      } catch (err) {
        setPermission("denied");
      }
    };

    const handlePermissionChange = (e: any) => {
      setPermission(e.target.state);
    };

    getPermission();

    const permissionStatus = navigator.permissions.query({
      name: "camera" as any,
    });
    permissionStatus.then((perm) => {
      perm.onchange = handlePermissionChange;
    });

    return () => {
      permissionStatus.then((permission) => {
        permission.onchange = null;
      });
    };
  }, []);

  return permission;
}
