import { useContext, useState } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import { AxiosError } from "axios";
import { ApiContext } from "../components/context/ApiContext";
import { StyledButton } from "../components/common/StyledButton";
import { ErrorResponse, LocationStyles, LoginResponse, UserRole } from "../generated/data-contracts";
import { RECOVERY_TOKEN_KEY, ROUTES, TOKEN_KEY } from "../constants";
import Loader from "../components/common/Loader";
import { validatePassword } from "../utils/validation";
import { OrgContext } from "../components/context/OrgContext";
import CustomInputText from "../components/common/CustomInputText";
import { getInfoFromToken } from "../utils/token";
import { getOrgStyles } from "../utils/localStorage";

function PasswordRecoveryPage() {
    const navigate = useNavigate();
    const [searchParams] = useSearchParams();
    const { setOrgContext } = useContext(OrgContext);
    const { login } = useContext(ApiContext);
    const [orgStyles] = useState<LocationStyles>(getOrgStyles());
    const recoveryToken = searchParams.get(RECOVERY_TOKEN_KEY);
    const [password, setPassword] = useState<string>("");
    const [confirmPassword, setConfirmPassword] = useState<string>("");
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [recoverError, setRecoverError] = useState<string>("");
    const [recoverMessage, setRecoverMessage] = useState<string>("");

    const valid = () => validatePassword(password) && password === confirmPassword;

    const handleRecover = () => {
        if (!password) return setRecoverError("Password is required");
        if (!confirmPassword) return setRecoverError("Confirm password is required");
        if (password !== confirmPassword) return setRecoverError("Passwords do not match");
        if (!validatePassword(password)) return setRecoverError("Password must be at least 8 characters long");
        setRecoverError("");
        setRecoverMessage("");
        setIsLoading(true);

        login
            ?.resetPasswordCreate({ recoveryToken: recoveryToken!, password })
            .then(({ data }: { data: LoginResponse }) => {
                if (data.token) {
                    localStorage.setItem(TOKEN_KEY, data.token);
                    setOrgContext();
                    switch (getInfoFromToken()?.role) {
                        case UserRole.RolePricer:
                            navigate(ROUTES.PRICER_PAGE);
                            break;
                        case UserRole.RoleLister:
                            navigate(ROUTES.LISTER_PAGE);
                            break;
                        default:
                            setRecoverError("Invalid user role. Please contact your administrator.");
                    }
                } else {
                    setRecoverError("An error occurred. Please try again.");
                    setIsLoading(false);
                }
            })
            .catch((err: AxiosError) => {
                setRecoverError((err.response?.data as ErrorResponse)?.error || "An error occurred. Please try again.");
                setIsLoading(false);
            })
            .finally(() => {
                setIsLoading(false);
            });
    };

    if (!recoveryToken) {
        return (
            <div className="h-dvh flex items-center justify-center">
                <div className="flex flex-col items-center gap-1 bg-thriftlyOffWhite p-10 border border-solid rounded-lg w-[500px]">
                    <img width="200px" src={orgStyles.logo} alt="logo" />
                    <h1 className="my-4 text-xl">Reset your password</h1>

                    <p className="mt-4 text-red-500 text-center">
                        Missing recovery token.
                        <br />
                        Please try recovering your password again.
                    </p>

                    <hr className="h-px my-4 bg-thriftlyForest w-full" />

                    <a href={ROUTES.LOGIN} className="text-thriftlyForest">
                        Back to log in
                    </a>
                </div>
            </div>
        );
    }

    return (
        <div className="h-dvh flex items-center justify-center">
            <div className="flex flex-col items-center gap-1 bg-thriftlyOffWhite p-10 border border-solid rounded-lg w-[500px]">
                <img src={orgStyles.logo} alt="logo" />
                <h1 className="my-4 text-xl">Reset your password</h1>

                <CustomInputText
                    className="w-full"
                    type="password"
                    label="Password"
                    placeholder="Password"
                    value={password}
                    onChange={setPassword}
                />

                <CustomInputText
                    className="w-full"
                    type="password"
                    label="Confirm password"
                    placeholder="Confirm password"
                    value={confirmPassword}
                    onChange={setConfirmPassword}
                    onEnter={handleRecover}
                />

                <StyledButton
                    backgroundColor={orgStyles.primaryColor}
                    disabled={!valid()}
                    className="w-full"
                    onClick={handleRecover}>
                    Update password
                </StyledButton>

                {isLoading && (
                    <div className="mt-4">
                        <Loader />
                    </div>
                )}
                {recoverError && <p className="mt-4 text-red-500">{recoverError}</p>}
                {recoverMessage && <p className="mt-4 text-thriftlyForest">{recoverMessage}</p>}

                <hr className="h-px my-4 bg-thriftlyForest w-full" />

                <a href={ROUTES.LOGIN} className="text-thriftlyForest">
                    Back to log in
                </a>
            </div>
        </div>
    );
}

export default PasswordRecoveryPage;
