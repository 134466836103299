import { MdCancel } from "react-icons/md";

export default function ImageModal({ image, onHide }: {
    image?: string;
    onHide: () => void;
}) {
    return (
        <>
            <div className="absolute top-0 left-0 w-full h-full bg-thriftlyGreyDark opacity-75" />
            <div className="absolute top-0 left-0 w-full h-full flex items-center justify-center" onClick={onHide}>
                <div className="flex justify-end p-1">
                    <MdCancel className="absolute text-4xl text-black bg-thriftlyOffWhite rounded-thriftlyIcon cursor-pointer" />
                    <div className="rounded-thriftlyImage overflow-hidden w-full border-2 bg-thriftlyGreyDark m-2">
                        <img className="min-h-screen-1/2 max-h-screen-4/5 object-contain" src={image} alt="item image" />
                    </div>
                </div>
            </div>
        </>
    );
}
