/* eslint-disable */
/* tslint:disable */
/*
 * ---------------------------------------------------------------
 * ## THIS FILE WAS GENERATED VIA SWAGGER-TYPESCRIPT-API        ##
 * ##                                                           ##
 * ## AUTHOR: acacode                                           ##
 * ## SOURCE: https://github.com/acacode/swagger-typescript-api ##
 * ---------------------------------------------------------------
 */

import {
  ErrorResponse,
  ErrorsResponse,
  FeedbackRequest,
  GeneratedProcessResponse,
  ItemsRemainingResponse,
  ItemsTodayResponse,
  ProcessResponse,
  ProcessesResponse,
  UpdateProcessRequest,
} from "./data-contracts";
import { ContentType, HttpClient, RequestParams } from "./http-client";

export class Processes<SecurityDataType = unknown> extends HttpClient<SecurityDataType> {
  /**
   * @description get processes
   *
   * @tags processes
   * @name ProcessesList
   * @summary Get Processes
   * @request GET:/processes
   * @secure
   */
  processesList = (
    query?: {
      /** Date */
      date?: string;
      /** Location ID */
      locationId?: string;
      /** User ID */
      userId?: string;
    },
    params: RequestParams = {},
  ) =>
    this.request<ProcessesResponse, ErrorResponse>({
      path: `/processes`,
      method: "GET",
      query: query,
      secure: true,
      type: ContentType.Json,
      format: "json",
      ...params,
    });
  /**
   * @description upload an image to process
   *
   * @tags processes
   * @name ProcessesCreate
   * @summary Upload an image to process
   * @request POST:/processes
   * @secure
   */
  processesCreate = (
    data: {
      /** Image */
      image?: File;
    },
    query?: {
      /** Category */
      category?: string;
      /** Condition */
      condition?: string;
      /** Gender */
      gender?: string;
      /** Price */
      price?: string;
      /** Processor */
      processor?: string;
      /** Station Type */
      stationType?: string;
      /** User ID */
      userId?: string;
    },
    params: RequestParams = {},
  ) =>
    this.request<ProcessResponse, ErrorResponse>({
      path: `/processes`,
      method: "POST",
      query: query,
      body: data,
      secure: true,
      type: ContentType.FormData,
      format: "json",
      ...params,
    });
  /**
   * @description get next item
   *
   * @tags processes
   * @name ItemsNextList
   * @summary Get Next Item
   * @request GET:/processes/items/next
   * @secure
   */
  itemsNextList = (params: RequestParams = {}) =>
    this.request<ProcessResponse, ErrorResponse>({
      path: `/processes/items/next`,
      method: "GET",
      secure: true,
      type: ContentType.Json,
      format: "json",
      ...params,
    });
  /**
   * @description get items remaining
   *
   * @tags processes
   * @name ItemsRemainingList
   * @summary Get Items Remaining
   * @request GET:/processes/items/remaining
   * @secure
   */
  itemsRemainingList = (params: RequestParams = {}) =>
    this.request<ItemsRemainingResponse, ErrorResponse>({
      path: `/processes/items/remaining`,
      method: "GET",
      secure: true,
      type: ContentType.Json,
      format: "json",
      ...params,
    });
  /**
   * @description get user items today
   *
   * @tags processes
   * @name ItemsTodayList
   * @summary Get Items Today
   * @request GET:/processes/items/today
   * @secure
   */
  itemsTodayList = (
    query?: {
      /** User ID */
      userId?: string;
    },
    params: RequestParams = {},
  ) =>
    this.request<ItemsTodayResponse, ErrorResponse>({
      path: `/processes/items/today`,
      method: "GET",
      query: query,
      secure: true,
      type: ContentType.Json,
      format: "json",
      ...params,
    });
  /**
   * @description Shopify Orders Fulfilled Webhook Handler
   *
   * @tags processes
   * @name ShopifyOrdersFulfilledCreate
   * @summary Shopify Orders Fulfilled Webhook Handler
   * @request POST:/processes/shopify/orders/fulfilled
   */
  shopifyOrdersFulfilledCreate = (request: string, params: RequestParams = {}) =>
    this.request<void, ErrorsResponse>({
      path: `/processes/shopify/orders/fulfilled`,
      method: "POST",
      body: request,
      type: ContentType.Json,
      ...params,
    });
  /**
   * @description Sync Shopify Orders
   *
   * @tags processes
   * @name ShopifyOrdersSyncCreate
   * @summary Sync Shopify Orders
   * @request POST:/processes/shopify/orders/sync
   * @secure
   */
  shopifyOrdersSyncCreate = (
    query: {
      /** End Date */
      endDate: string;
      /** Organization ID */
      orgId: string;
      /** Start Date */
      startDate: string;
    },
    params: RequestParams = {},
  ) =>
    this.request<void, ErrorsResponse>({
      path: `/processes/shopify/orders/sync`,
      method: "POST",
      query: query,
      secure: true,
      type: ContentType.Json,
      ...params,
    });
  /**
   * @description preview a process
   *
   * @tags processes
   * @name ProcessesDetail
   * @summary Preview a process
   * @request GET:/processes/{id}
   */
  processesDetail = (id: string, params: RequestParams = {}) =>
    this.request<ProcessResponse, ErrorResponse>({
      path: `/processes/${id}`,
      method: "GET",
      type: ContentType.Json,
      format: "json",
      ...params,
    });
  /**
   * @description update a process
   *
   * @tags processes
   * @name ProcessesUpdate
   * @summary Update a process
   * @request PUT:/processes/{id}
   * @secure
   */
  processesUpdate = (id: string, request: UpdateProcessRequest, params: RequestParams = {}) =>
    this.request<ProcessResponse, ErrorResponse>({
      path: `/processes/${id}`,
      method: "PUT",
      body: request,
      secure: true,
      type: ContentType.Json,
      format: "json",
      ...params,
    });
  /**
   * @description Create feedback for a process
   *
   * @tags processes
   * @name FeedbackCreate
   * @summary Create feedback for a process
   * @request POST:/processes/{id}/feedback
   */
  feedbackCreate = (id: string, requestBody: FeedbackRequest, params: RequestParams = {}) =>
    this.request<void, ErrorResponse>({
      path: `/processes/${id}/feedback`,
      method: "POST",
      body: requestBody,
      type: ContentType.Json,
      ...params,
    });
  /**
   * @description generate a process
   *
   * @tags processes
   * @name GenerateCreate
   * @summary Generate a process
   * @request POST:/processes/{id}/generate
   * @secure
   */
  generateCreate = (
    id: string,
    query?: {
      /** Processor */
      processor?: string;
    },
    params: RequestParams = {},
  ) =>
    this.request<GeneratedProcessResponse, ErrorResponse>({
      path: `/processes/${id}/generate`,
      method: "POST",
      query: query,
      secure: true,
      type: ContentType.Json,
      format: "json",
      ...params,
    });
  /**
   * @description upload an additional image for a process
   *
   * @tags processes
   * @name ImageCreate
   * @summary Upload an additional image for a process
   * @request POST:/processes/{id}/image
   * @secure
   */
  imageCreate = (
    id: string,
    data: {
      /** Image */
      image?: File;
    },
    params: RequestParams = {},
  ) =>
    this.request<ProcessResponse, ErrorResponse>({
      path: `/processes/${id}/image`,
      method: "POST",
      body: data,
      secure: true,
      type: ContentType.FormData,
      format: "json",
      ...params,
    });
  /**
   * @description download a process image
   *
   * @tags processes
   * @name ImageDetail
   * @summary Download a process image
   * @request GET:/processes/{id}/image/{number}
   * @secure
   */
  imageDetail = (
    id: string,
    number: string,
    query: {
      /** Height */
      height: string;
      /** Width */
      width: string;
    },
    params: RequestParams = {},
  ) =>
    this.request<void, ErrorResponse>({
      path: `/processes/${id}/image/${number}`,
      method: "GET",
      query: query,
      secure: true,
      type: ContentType.Json,
      ...params,
    });
  /**
   * @description Get a process label
   *
   * @tags processes
   * @name LabelDetail
   * @summary Get a process label
   * @request GET:/processes/{id}/label
   */
  labelDetail = (
    id: string,
    query?: {
      /** Print */
      print?: string;
    },
    params: RequestParams = {},
  ) =>
    this.request<void, ErrorResponse>({
      path: `/processes/${id}/label`,
      method: "GET",
      query: query,
      type: ContentType.Json,
      ...params,
    });
}
