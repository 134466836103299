import { useState } from "react";
import ReactLoading from "react-loading";
import { LocationStyles } from "../../generated/data-contracts";
import { getOrgStyles } from "../../utils/localStorage";

type LoaderProps = {
  color?: string;
  className?: string;
}

export default function Loader({ color, className }: LoaderProps) {
  const [orgStyles] = useState<LocationStyles>(getOrgStyles());

  return <div
    className={className}
    style={{ display: "flex", flexDirection: "row", justifyContent: "center" }}>
    <ReactLoading type="spin" color={color || orgStyles.primaryColor} />
  </div>;
}
