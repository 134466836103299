type Option = {
  value: string;
  label: string;
};

type Props = {
  capitalize?: boolean;
  label?: string;
  placeholder: string;
  onChange?: (value: string) => void;
  onBlur?: (value: string) => void;
  value?: string;
  options: Option[];
};

export default function CustomDropdownBlack(props: Props) {
  const { capitalize, label, placeholder, value, onChange, onBlur, options } = props;

  return (
    <div className="flex w-full flex-col bg-thriftlyBlackBackground p-2 gap-2">
      {label && (
        <label className="ml-2 text-xs font-semibold text-thriftlyOffWhite" htmlFor={label}>
          {label}
        </label>
      )}
      <div className="flex flex-row rounded-lg border-2 border-thriftlyGreyDark border">
        <select
          className={`w-full rounded-md border-none px-2 focus:outline-none focus:ring-0 bg-thriftlyBlackBackground text-thriftlyOffWhite ${capitalize ? "capitalize" : ""}`}
          name={label}
          onChange={(e) => onChange && onChange(e.target.value)}
          onBlur={(e) => onBlur && onBlur(e.target.value)}
          value={value}
        >
          {placeholder && (<option value="" disabled hidden>{placeholder}</option>)}
          {options.map((option) => {
            return (
              <option key={option.value} value={option.value} className={capitalize ? "capitalize" : ""}>
                {option.label}
              </option>
            )
          })}
        </select>
      </div>
    </div>
  );
}
