import { useContext, useEffect, useState } from "react";
import { toast } from "react-toastify";
import Header from "../../components/header/Header";
import MenuPopover from "../../components/header/MenuPopover";
import { Button } from "../../components/common/Button";
import { ApiContext } from "../../components/context/ApiContext";
import { Container, ContainerResponse, UserRole } from "../../generated/data-contracts";
import CustomInputText from "../../components/common/CustomInputText";
import { OrgContext } from "../../components/context/OrgContext";
import { isMobile } from "react-device-detect";
import { canPrint } from "../../utils/printers";

export default function ContainersPage() {
    const { VITE_API_BASE_URL } = import.meta.env;
    const { role, email } = useContext(OrgContext);
    const { containers: containersApi } = useContext(ApiContext);

    const [containerName, setContainerName] = useState<string>();
    const [container, setContainer] = useState<Container>();
    const [selectedPrinter, setSelectedPrinter] = useState<any>();

    useEffect(() => {
        if (!canPrint() || selectedPrinter !== undefined) return;
        (window as any).BrowserPrint.getDefaultDevice("printer", function (printer: any) {
            setSelectedPrinter(printer);
        }, function (error: string) {
            toast(`Error getting default printer: ${error}`);
        })
    }, [1]);

    const printLabel = (container: Container) => {
        const url = `${VITE_API_BASE_URL}/containers/${container.id}/label?print=true`;
        selectedPrinter.sendFile(url, undefined, (error: any) => {
            toast(`Error printing label: ${error}`);
            console.error(`Error printing label: ${error}`);
        });
    }

    const createContainer = () => {
        // Call API to create container
        containersApi
            ?.containersCreate({ name: containerName })
            .then(({ data }: { data: ContainerResponse }) => {
                toast("Container created.");
                setContainer(data.container);
                printLabel(data.container!);
            })
            .catch(() => { toast("An error occurred. Please try again.") });
    }

    return (
        <div className="h-dvh overscroll-none overflow-y-clip">
            <Header
                darkMode
                left={<MenuPopover role={role as UserRole} darkMode />}
                right={!isMobile && (<div className="flex justify-end pr-4 text-thriftlyOffWhite">{email}</div>)}
            />
            <div className="w-full flex justify-center border-t-2 border-thriftlyGreyDark pt-2">
                <div className="flex flex-col items-center gap-2 px-4 w-96">
                    <div className="text-2xl text-thriftlyOffWhite text-center">Create container</div>
                    <CustomInputText
                        className="w-full"
                        disabled={container !== undefined}
                        label="Container name"
                        placeholder="Container name"
                        onChange={setContainerName}
                    />
                    {container ? (
                        <>
                            <Button className="w-full" onClick={() => printLabel(container)}>Reprint Label</Button>
                            <Button className="w-full" onClick={() => setContainer(undefined)}>Create more</Button>
                        </>
                    ) : (
                        <Button className="w-full" onClick={() => createContainer()}>Create</Button>
                    )}
                </div>
            </div>
        </div >
    );
}