/* eslint-disable */
/* tslint:disable */
/*
 * ---------------------------------------------------------------
 * ## THIS FILE WAS GENERATED VIA SWAGGER-TYPESCRIPT-API        ##
 * ##                                                           ##
 * ## AUTHOR: acacode                                           ##
 * ## SOURCE: https://github.com/acacode/swagger-typescript-api ##
 * ---------------------------------------------------------------
 */

import { ContainerResponse, CreateContainerRequest, ErrorResponse, PlaceProcessesRequest } from "./data-contracts";
import { ContentType, HttpClient, RequestParams } from "./http-client";

export class Containers<SecurityDataType = unknown> extends HttpClient<SecurityDataType> {
  /**
   * @description Get all containers
   *
   * @tags containers
   * @name ContainersList
   * @summary Get all containers
   * @request GET:/containers
   * @secure
   */
  containersList = (params: RequestParams = {}) =>
    this.request<ContainerResponse, ErrorResponse>({
      path: `/containers`,
      method: "GET",
      secure: true,
      type: ContentType.Json,
      format: "json",
      ...params,
    });
  /**
   * @description Create a container
   *
   * @tags containers
   * @name ContainersCreate
   * @summary Create a container
   * @request POST:/containers
   * @secure
   */
  containersCreate = (requestBody: CreateContainerRequest, params: RequestParams = {}) =>
    this.request<ContainerResponse, ErrorResponse>({
      path: `/containers`,
      method: "POST",
      body: requestBody,
      secure: true,
      type: ContentType.Json,
      format: "json",
      ...params,
    });
  /**
   * @description Get a container
   *
   * @tags containers
   * @name ContainersDetail
   * @summary Get a container
   * @request GET:/containers/{id}
   * @secure
   */
  containersDetail = (id: string, params: RequestParams = {}) =>
    this.request<ContainerResponse, ErrorResponse>({
      path: `/containers/${id}`,
      method: "GET",
      secure: true,
      type: ContentType.Json,
      format: "json",
      ...params,
    });
  /**
   * @description Get a container label
   *
   * @tags containers
   * @name LabelDetail
   * @summary Get a container label
   * @request GET:/containers/{id}/label
   */
  labelDetail = (
    id: string,
    query?: {
      /** Print */
      print?: string;
    },
    params: RequestParams = {},
  ) =>
    this.request<void, ErrorResponse>({
      path: `/containers/${id}/label`,
      method: "GET",
      query: query,
      type: ContentType.Json,
      ...params,
    });
  /**
   * @description Place processes in a container
   *
   * @tags containers
   * @name PlaceCreate
   * @summary Place processes in a container
   * @request POST:/containers/{id}/place
   * @secure
   */
  placeCreate = (id: string, requestBody: PlaceProcessesRequest, params: RequestParams = {}) =>
    this.request<void, ErrorResponse>({
      path: `/containers/${id}/place`,
      method: "POST",
      body: requestBody,
      secure: true,
      type: ContentType.Json,
      ...params,
    });
}
