import { ReactNode } from "react";

export default function Modal(props: {
  children: ReactNode;
}) {
  const { children } = props;

  return (
    <div className="flex shrink-0 w-full p-4 rounded-t-thriftlyModal bg-thriftlyOffWhite mt-auto">
      {children}
    </div>
  );
}
