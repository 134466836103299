import { useContext, useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { ApiContext } from "../../components/context/ApiContext";
import { ErrorResponse, Process, ProcessResponse } from "../../generated/data-contracts";
import { AxiosError } from "axios";
import { toast } from "react-toastify";
import { ROUTES } from "../../constants";
import Loader from "../../components/common/Loader";
import ProcessPage from "./ProcessPage";
import { getPriceAppConfig } from "../../utils/localStorage";
import { isSet } from "../../utils/validation";

export default function ProcessPageLoader() {
  const navigate = useNavigate();
  const params = useParams();
  const { processes: processApi } = useContext(ApiContext);

  const [isLoading, setIsLoading] = useState(true);
  const [process, setProcess] = useState<Process>();

  // fetch process using path param
  useEffect(() => {
    processApi
      ?.processesDetail(params.id!)
      .then(({ data }: { data: ProcessResponse }) => {
        if (!data.process) {
          toast("An error occurred. Please try again.");
          navigate(ROUTES.HOME);
          return;
        }
        let processDraft = data.process;
        if (getPriceAppConfig().descriptionTemplate && !isSet(processDraft.notes)) {
          processDraft = { ...processDraft, notes: getPriceAppConfig().descriptionTemplate };
        }
        setProcess(processDraft);
      })
      .catch((err: AxiosError) => {
        console.error((err.response?.data as ErrorResponse)?.error);
        toast("An error occurred. Please try again.");
        navigate(ROUTES.HOME);
      })
      .finally(() => { setIsLoading(false) });
  }, [1]);

  if (isLoading) {
    return <div className="h-screen flex items-center justify-center"><Loader /></div>;
  }

  return <ProcessPage process={process} />;
}
