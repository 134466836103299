/* eslint-disable */
/* tslint:disable */
/*
 * ---------------------------------------------------------------
 * ## THIS FILE WAS GENERATED VIA SWAGGER-TYPESCRIPT-API        ##
 * ##                                                           ##
 * ## AUTHOR: acacode                                           ##
 * ## SOURCE: https://github.com/acacode/swagger-typescript-api ##
 * ---------------------------------------------------------------
 */

import {
  CreateLocationRequest,
  ErrorResponse,
  LocationResponse,
  LocationsResponse,
  UpdateLocationRequest,
} from "./data-contracts";
import { ContentType, HttpClient, RequestParams } from "./http-client";

export class Locations<SecurityDataType = unknown> extends HttpClient<SecurityDataType> {
  /**
   * @description get locations
   *
   * @tags locations
   * @name LocationsList
   * @summary Get locations
   * @request GET:/locations
   * @secure
   */
  locationsList = (params: RequestParams = {}) =>
    this.request<LocationsResponse, ErrorResponse>({
      path: `/locations`,
      method: "GET",
      secure: true,
      type: ContentType.Json,
      format: "json",
      ...params,
    });
  /**
   * @description get location by ID
   *
   * @tags locations
   * @name LocationsDetail
   * @summary Get a location
   * @request GET:/locations/{locationId}
   */
  locationsDetail = (locationId: string, params: RequestParams = {}) =>
    this.request<LocationResponse, ErrorResponse>({
      path: `/locations/${locationId}`,
      method: "GET",
      type: ContentType.Json,
      format: "json",
      ...params,
    });
  /**
   * @description create location
   *
   * @tags locations
   * @name LocationsUpdate
   * @summary Create a location
   * @request PUT:/locations/{locationId}
   * @secure
   */
  locationsUpdate = (locationId: string, requestBody: CreateLocationRequest, params: RequestParams = {}) =>
    this.request<LocationResponse, ErrorResponse>({
      path: `/locations/${locationId}`,
      method: "PUT",
      body: requestBody,
      secure: true,
      type: ContentType.Json,
      format: "json",
      ...params,
    });
  /**
   * @description delete location
   *
   * @tags locations
   * @name LocationsDelete
   * @summary Delete a location
   * @request DELETE:/locations/{locationId}
   * @secure
   */
  locationsDelete = (locationId: string, params: RequestParams = {}) =>
    this.request<void, ErrorResponse>({
      path: `/locations/${locationId}`,
      method: "DELETE",
      secure: true,
      type: ContentType.Json,
      ...params,
    });
  /**
   * @description update location
   *
   * @tags locations
   * @name LocationsPartialUpdate
   * @summary Update a location
   * @request PATCH:/locations/{locationId}
   * @secure
   */
  locationsPartialUpdate = (locationId: string, requestBody: UpdateLocationRequest, params: RequestParams = {}) =>
    this.request<LocationResponse, ErrorResponse>({
      path: `/locations/${locationId}`,
      method: "PATCH",
      body: requestBody,
      secure: true,
      type: ContentType.Json,
      format: "json",
      ...params,
    });
}
