import { MdPrint } from "react-icons/md";
import { Button } from "../../../components/common/Button";
import ScanTile from "./ScanTile";
import CustomInputText from "../../../components/common/CustomInputText";
import { useState } from "react";

export default function ScanInstructions(props: {
    modalState: undefined | "editProcess" | "priceLabel" | "containerLabel" | "createContainerLabel";
    setModalState: (state: "editProcess" | "priceLabel" | "containerLabel" | "createContainerLabel") => void;
    printerName: string;
    unsavedChanges: boolean;
    onPrintPriceLabel: () => void;
    onPrintContainerLabel: (name: string) => void;
}) {
    const { modalState, setModalState, printerName, unsavedChanges, onPrintPriceLabel, onPrintContainerLabel } = props;
    const [newContainerName, setNewContainerName] = useState<string>("");

    return (
        <div className="flex flex-col gap-8 p-4">
            <ScanTile
                state={modalState === "priceLabel" ? "active" : "completed"}
                title="Attach item label and scan"
                subtitle={`Item label sent to ${printerName}`}
                image="/barcode_scanner_price_label.svg">
                <Button
                    className="w-full p-8 text-thriftlyBlack text-lg"
                    onClick={() => {
                        if (unsavedChanges) { return; }
                        onPrintPriceLabel();
                    }}
                    disabled={unsavedChanges}>
                    <MdPrint className="w-[24px] h-[24px]" />Reprint Label
                </Button>
            </ScanTile>
            <ScanTile
                state={modalState === "containerLabel" || modalState === "createContainerLabel" ? "active" : "disabled"}
                title="Place item in container and scan container label"
                image="/barcode_scanner_container_label.svg">
                {modalState === "createContainerLabel" ? (
                    <div className="flex flex-col gap-4">
                        <CustomInputText
                            textColor="text-thriftlyGreyLight"
                            backgroundColor="bg-thriftlyBlackBackground"
                            className="border-2 border-thriftlyGrey"
                            label="Container name"
                            placeholder="Container name"
                            value={newContainerName}
                            onChange={(value) => setNewContainerName(value)}
                        />
                        <Button
                            className="w-full p-8 text-thriftlyBlack text-lg"
                            onClick={() => {
                                onPrintContainerLabel(newContainerName);
                                setModalState("containerLabel");
                                setNewContainerName("");
                            }}>
                            Create container
                        </Button>
                    </div>
                ) : (
                    <Button
                        className="w-full p-8 text-thriftlyBlack text-lg"
                        onClick={() => setModalState("createContainerLabel")}>
                        Create new container
                    </Button>
                )}
            </ScanTile>
        </div>
    );
}
