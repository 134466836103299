import { useContext, useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { toast } from "react-toastify";
import { ShareIcon, CartIcon, ThumbsUpIcon, ThumbsDownIcon } from "@shopify/polaris-icons";
import { AxiosError } from "axios";
import Header from "../../components/header/Header";
import { Comparable, ErrorResponse, PricingProcessor, Process, ProcessResponse } from "../../generated/data-contracts";
import ComparableTileMobile from "../ProcessPage/Components/ComparableTileMobile";
import Modal from "../../components/Modal";
import ImageModal from "../../components/ImageModal";
import { Button } from "../../components/common/Button";
import { ApiContext } from "../../components/context/ApiContext";
import ToggleIcon from "../../components/common/ToggleIcon";
import CustomInputTextArea from "../../components/common/CustomInputTextArea";
import Loader from "../../components/common/Loader";

export default function ShopPage() {
    const params = useParams();
    const { processes: processApi } = useContext(ApiContext);

    const [isLoading, setIsLoading] = useState(true);
    const [process, setProcess] = useState<Process | null>(null);
    const [selectedImage, setSelectedImage] = useState<string>();
    const [showImageModal, setShowImageModal] = useState(false);
    const [feedback, setFeedback] = useState<"up" | "down" | undefined>();
    const [feedbackComment, setFeedbackComment] = useState("");
    const [showFeedbackInput, setShowFeedbackInput] = useState(false);

    // get process id from route params
    useEffect(() => {
        processApi
            ?.processesDetail(params.id!)
            .then(({ data }: { data: ProcessResponse }) => {
                if (data.process) {
                    setProcess(data.process);

                    if ((data.process.processor == PricingProcessor.ProcessorNone || data.process.comparables?.length == 0) &&
                        data.process.inventory != undefined && data.process.inventory.cartLink != undefined) {
                        window.location.href = data.process.inventory.cartLink;
                    } else {
                        setIsLoading(false);
                    }
                }
            })
            .catch((err: AxiosError) => {
                console.error((err.response?.data as ErrorResponse)?.error);
                toast("An error occurred. Please try again.");
            });
    }, [1]);

    const selectImage = (image: string | undefined) => {
        setSelectedImage(image);
        setShowImageModal(true);
    }

    const onFeedback = (type: "up" | "down") => () => {
        setFeedback(type);
        setShowFeedbackInput(true);
    }

    const sendFeedback = () => {
        setShowFeedbackInput(false);
        setFeedbackComment("");
        toast("Thanks! Your feedback helps improve listings.")

        processApi
            ?.feedbackCreate(process?.id!, { feedback, comment: feedbackComment })
            .then()
            .catch((err: AxiosError) => {
                console.error((err.response?.data as ErrorResponse)?.error);
            });
    }

    const getSrcWithSize = (src: string) => {
        if (!src) { return "" }
        return `${src}?width=300&height=300`;
    }

    if (isLoading) {
        return (
            <div className="w-full h-dvh flex justify-center items-center ">
                <Loader />
            </div>
        );
    }

    return (
        <div className="h-dvh overscroll-none overflow-y-clip flex flex-col justify-between">
            <Header
                darkMode
                right={<div className="text-right pr-2 text-white">Get 10% off</div>}
            />
            <div className="px-4 py-2 text-white border-t border-thriftlyGreyDark">
                Comparables
            </div>
            <div className="flex shrink flex-col gap-4 px-4 pb-4 overflow-y-scroll">
                {process?.comparables?.map((comparable: Comparable, idx: number) => {
                    return (
                        <div key={idx}>
                            <ComparableTileMobile
                                comparable={comparable}
                                onClickImage={(c: Comparable) => { selectImage(c.image); }}
                            />
                        </div>
                    );
                })}
            </div>
            <Modal>
                <div className="flex flex-col w-full gap-4">
                    <div className="flex flex-row w-full gap-2 p-2 items-center">
                        <div className="flex items-center shrink-0 rounded-thriftlyImage overflow-hidden">
                            <img className="w-[80px] object-contain" src={getSrcWithSize(process?.images?.[0] || "")}
                                onClick={(e) => { e.stopPropagation(); selectImage(process?.images?.[0] || ""); }} />
                        </div>
                        <div className="w-full flex flex-col">
                            <div className="flex flex-row justify-between">
                                <div className="text-xl text-thriftlyBlackText">{process?.price ? process.price : null}</div>
                                <div className="flex flex-row gap-2">
                                    <ToggleIcon
                                        defaultColor="black"
                                        height={32}
                                        icon={ThumbsUpIcon}
                                        onClick={onFeedback("up")}
                                        toggled={feedback === "up"}
                                        toggledColor="white"
                                    />
                                    <ToggleIcon
                                        defaultColor="black"
                                        height={32}
                                        icon={ThumbsDownIcon}
                                        onClick={onFeedback("down")}
                                        toggled={feedback === "down"}
                                        toggledColor="white"
                                    />
                                </div>
                            </div>
                            <div className="opacity-90 text-sm text-black capitalize">{process?.condition}</div>
                            <div className="opacity-65 text-sm line-clamp-2">{process?.title}</div>
                            <div className="opacity-65 text-sm line-clamp-1">{process?.notes}</div>
                        </div>
                    </div>
                    {showFeedbackInput && (
                        <div className="flex flex-col gap-2">
                            <CustomInputTextArea
                                label="Comment"
                                placeholder="Comment"
                                value={feedbackComment}
                                onChange={setFeedbackComment}
                                clickToSelect />
                        </div>
                    )}
                    <div className="flex flex-col gap-4">
                        {!showFeedbackInput ? (
                            <>
                                <Button
                                    className="w-full border-black"
                                    onClick={async () => {
                                        const data = {
                                            url: window.location.href,
                                            text: "",
                                        };

                                        if (navigator.share !== undefined) {
                                            try {
                                                await navigator.share(data);
                                            } catch (e) {
                                                // suppress exception 
                                            }
                                        } else {
                                            toast("Sharing is not supported on this device.");
                                        }
                                    }}
                                >
                                    <ShareIcon height={40} fill="#000000" /> Share
                                </Button>
                                <Button
                                    backgroundColor="bg-thriftlyBlackBackground"
                                    textColor="text-white"
                                    className="w-full border-black"
                                    onClick={() => {
                                        window.open(process?.inventory?.cartLink, "_blank");
                                    }}
                                >
                                    <CartIcon height={32} fill="#FFFFFF" /> Add to cart
                                </Button>
                            </>
                        ) : <>
                            <Button
                                className="w-full"
                                onClick={() => { setFeedback(undefined); setShowFeedbackInput(false); }}
                            >
                                Cancel
                            </Button>
                            <Button
                                backgroundColor="bg-thriftlyBlackBackground"
                                textColor="text-white"
                                className="w-full border-black"
                                onClick={sendFeedback}
                            >
                                Submit
                            </Button>
                        </>}
                    </div>
                </div>
            </Modal>
            {showImageModal && (<ImageModal image={selectedImage} onHide={() => setShowImageModal(false)} />)}
        </div>
    )
}