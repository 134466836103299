import { jwtDecode } from "jwt-decode";
import { TOKEN_KEY, SESSION_KEY } from "../constants";
import CustomerToken from "../api/token";

export function getInfoFromToken() {
    const token = localStorage.getItem(TOKEN_KEY);
    if (token) {
        const parsedToken = jwtDecode<CustomerToken>(token);

        return {
            authenticated: parsedToken.exp * 1000 > Date.now(),
            userId: parsedToken.id,
            orgId: parsedToken.orgId || "",
            role: parsedToken.role,
            email: parsedToken.email || "",
            session: localStorage.getItem(SESSION_KEY) || undefined,
            token,
        };
    }

    return { authenticated: false, userId: "", orgId: "", role: "", email: "", issuer: "", session: undefined, token };
}

export function getInfoFromSessionToken() {
    const token = localStorage.getItem(SESSION_KEY);
    if (token) {
        const parsedToken = jwtDecode<CustomerToken>(token);

        return {
            authenticated: parsedToken.exp * 1000 > Date.now(),
            userId: parsedToken.id,
            orgId: parsedToken.orgId || "",
            role: parsedToken.role,
            email: parsedToken.email || "",
            session: undefined,
            token,
        };
    }

    return { authenticated: false, userId: "", orgId: "", role: "", email: "", issuer: "", session: undefined, token };
}

export function isTokenExpired() {
    const token = localStorage.getItem(TOKEN_KEY);
    if (token) {
        const parsedToken = jwtDecode<CustomerToken>(token);
        if (parsedToken.exp * 1000 < Date.now()) {
            return true;
        }
    }

    return false;
}