import React from "react";
import { useNavigate } from "react-router-dom";
import { MenuIcon, XIcon } from "@shopify/polaris-icons";
import { Popover } from "@mui/material";
import { ROUTES } from "../../constants";
import { UserRole } from "../../generated/data-contracts";

type MenuPopoverProps = {
    role: UserRole;
    darkMode?: boolean;
}

export default function MenuPopover({ role, darkMode }: MenuPopoverProps) {
    const navigate = useNavigate();
    const inputRef = React.useRef<HTMLDivElement>(null);
    const [anchorEl, setAnchorEl] = React.useState<HTMLDivElement | null>(null);
    const open = Boolean(anchorEl);
    const id = open ? 'simple-popover' : undefined;

    const handleClick = () => {
        setAnchorEl(inputRef.current);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const getColorClasses = () => {
        return darkMode ? "bg-thriftlyGreyDark text-thriftlyOffWhite" : "bg-thriftlyGreyLight text-thriftlyBlackText";
    };

    return (
        <>
            <div ref={inputRef}>
                <MenuIcon fill={darkMode ? "#E3E5E7" : "#0D0D0D"} className="h-[21px] w-[21px] cursor-pointer" onClick={handleClick} />
            </div>
            <Popover
                id={id}
                open={open}
                anchorEl={anchorEl}
                onClose={handleClose}
                anchorOrigin={{ vertical: 'top', horizontal: 'left' }}
                transformOrigin={{ vertical: 'top', horizontal: 'left' }}
            >
                <div className={`flex flex-col gap-2 w-48 border border-thriftlyGrey rounded p-4 ${getColorClasses()}`}>
                    <XIcon fill={darkMode ? "#E3E5E7" : "#0D0D0D"} className="cursor-pointer h-6 w-6 ml-[-6px]" onClick={handleClose} />
                    {(role === UserRole.RoleAdmin) && (
                        <div
                            className={`cursor-pointer ${window.location.pathname === ROUTES.PRODUCTION_PAGE ? "font-bold" : ""}`}
                            onClick={() => navigate(ROUTES.PRODUCTION_PAGE)}>
                            Production
                        </div>
                    )}
                    {(role === UserRole.RolePricer) && (
                        <div
                            className={`cursor-pointer ${window.location.pathname === ROUTES.PRICER_PAGE ? "font-bold" : ""}`}
                            onClick={() => navigate(ROUTES.PRICER_PAGE)}>
                            Price
                        </div>
                    )}
                    {(role === UserRole.RoleLister) && (
                        <div
                            className={`cursor-pointer ${window.location.pathname === ROUTES.LISTER_PAGE ? "font-bold" : ""}`}
                            onClick={() => navigate(ROUTES.LISTER_PAGE)}>
                            Review</div>
                    )}
                    <div
                        className={`cursor-pointer ${window.location.pathname === ROUTES.PLACE_PAGE ? "font-bold" : ""}`}
                        onClick={() => navigate(ROUTES.PLACE_PAGE)}>
                        Place
                    </div>
                    <div
                        className={`cursor-pointer ${window.location.pathname === ROUTES.CONTAINERS_PAGE ? "font-bold" : ""}`}
                        onClick={() => navigate(ROUTES.CONTAINERS_PAGE)}>
                        Containers
                    </div>
                    {(role === UserRole.RolePricer) && (
                        <div
                            className={`cursor-pointer ${window.location.pathname === ROUTES.INVENTORY_PAGE ? "font-bold" : ""}`}
                            onClick={() => navigate(ROUTES.INVENTORY_PAGE)}>
                            Inventory
                        </div>
                    )}
                    <div
                        className={`cursor-pointer ${window.location.pathname === ROUTES.SETTINGS_PAGE ? "font-bold" : ""}`}
                        onClick={() => navigate(ROUTES.SETTINGS_PAGE)}>
                        Settings
                    </div>
                    <div className="cursor-pointer" onClick={() => navigate(ROUTES.LOGOUT)}>Log out</div>
                </div>
            </Popover >
        </>
    );
}
