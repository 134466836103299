type InputProps = {
  className?: string;
  textColor?: string;
  backgroundColor?: string;
  disabled?: boolean;
  label?: string;
  placeholder?: string;
  onChange: (value: string) => void;
  onBlur?: (value: string) => void;
  onEnter?: (value: string) => void;
  clickToSelect?: boolean;
  value?: string;
};

export default function CustomInputTextArea(props: InputProps) {
  const {
    className,
    textColor,
    backgroundColor,
    disabled,
    label,
    value,
    placeholder,
    onChange,
    onBlur,
    onEnter,
    clickToSelect
  } = props;

  const getTextColor = () => {
    if (disabled) { return "text-gray-500" };
    if (textColor) { return textColor }
    return "text-black";
  }

  const getBackgroundColor = () => {
    if (disabled) { return "bg-white" };
    if (backgroundColor) { return backgroundColor };
    return "bg-white";
  }

  return (
    <div className={`flex w-full flex-col rounded-lg border-gray-300 border p-2 ${getTextColor()} ${getBackgroundColor()} ${className}`}>
      {label && value !== "" && value !== undefined && (
        <label className={`ml-2 text-xs font-semibold ${getTextColor()} ${getBackgroundColor()}`} htmlFor={label}>
          {label}
        </label>
      )}
      <textarea
        className={`w-full h-full rounded-md border-none px-2 focus:outline-none focus:ring-0 ${getTextColor()} ${getBackgroundColor()}`}
        name={label}
        onChange={(e) => onChange && onChange(e.target.value)}
        onBlur={(e) => onBlur && onBlur(e.target.value)}
        onKeyDown={(e) => {
          if (onEnter && e.key === "Enter") {
            onEnter(value || "");
          }
        }}
        onClick={(e) => clickToSelect && window.innerWidth < 1000 && e.currentTarget.select()}
        value={value}
        placeholder={placeholder}
      />
    </div>
  );
}
