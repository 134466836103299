import { Process } from "../../generated/data-contracts";

export default function ProcessTile(props: {
  process: Process;
  onClick?: (process: Process) => void;
}) {
  const { process, onClick } = props;

  const getSrcWithSize = (src: string) => {
    if (!src) { return "" }
    return `${src}?width=300&height=300`;
  }

  return (
    <div
      className="flex flex-row cursor-pointer gap-2 text-white"
      onClick={() => onClick ? onClick(process) : null}
    >
      <div className="flex shrink-0 bg-thriftlyOffWhite rounded-thriftlyButton overflow-hidden items-center">
        <img className="max-h-[68px] w-[68px] object-contain" src={getSrcWithSize(process.images ? process.images[0] : "")} />
      </div>
      <div className="flex flex-col w-full">
        <div className="flex gap-4 justify-between">
          <div className="text-lg">{process.title}</div>
        </div>
        <div className="opacity-65 text-sm line-clamp-2">{process.price}</div>
      </div>
    </div>
  );
}
